export const APP_CONSTANTS = {
  development: {
    CASTTREE_SERVICE_BASE_URL: "https://esearchprod.casttree.com",
    PEERTUBE_BASE_URL: "https://peertubedev.casttree.in",
    RAZOR_PAY_KEY: "rzp_test_7IliuH9p5xRipC",
    GOOGLE_ANALYTICS_ID: "G-T68KSNXL5N",
    MICROSOFT_CLARITY_ID: 'l7pkjipslu',
    CASTTREE_MONGODB_BASE_URL: "https://asia-south1.gcp.data.mongodb-api.com",
  },
  production: {
    CASTTREE_SERVICE_BASE_URL: "https://prodapi.casttree.com",
    PEERTUBE_BASE_URL: "https://peertube-prod.casttree.com",
    RAZOR_PAY_KEY: "rzp_live_jB95DbanEjeWMy",
    GOOGLE_ANALYTICS_ID: "G-1KH8XTMKGP", 
    MICROSOFT_CLARITY_ID: 'miggsg2dx2',
    CASTTREE_MONGODB_BASE_URL: "https://asia-south1.gcp.data.mongodb-api.com",
  },
};


export const ENV = "production";
// export const ENV = "development";
