import React from "react";
import logoImage from "../assets/casttree_logo.png";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Fade from "react-awesome-reveal";

const RefundPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className="header-nav navbar-light event-header container"
        fixed="top"
        collapseOnSelect
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
          position: "relative",
        }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5 ct-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5 pt-5"
            />
          </Navbar.Brand>
        </Fade>
      </Navbar>
      <div className="landing-page-main">
        <div className="ct-p-5 ct-d-flex-justify-center-home">
          <div className="">
            <div className="" style={{ textAlign: "center", marginBottom: 50 }}>
              <h1 className="ct-cirka-size-50" style={{ color: "#FFF" }}>
                Refund Policy
              </h1>
            </div>
            <div className="">
              <p className="ct-text-18 ct-text-light-gray pb-3">
                As a subscriber to Casttree app (owned by Billion Faces
                Technologies Private Limited), you are free to cancel your
                subscription at any time. To do so, simply send an email to {" "}
                <a
                  href="mailto:customers@casttree.com"
                  style={{ color: "#ee36ff" }}
                >
                  customers@casttree.com
                </a>
                . Be sure to include the email address the account is associated
                with, and clarify if you have more than one active subscription.
                Cancellation will be effective with in 48 Hours, but you will
                retain access to the paid-only features for the period you have
                paid for. Once that period is over, your access will expire.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Refund Policy for subscription:
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                As stated in our terms of service, users must cancel their
                subscriptions before renewal if they do not wish to be charged.
                To help in this endeavour, Casttree sends out an email notice
                when their renewal is one month away on both annual and
                semi-annual subscriptions (no such notice exists for monthly
                subscriptions).
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                In the event that you request cancellation after-hours,
                providing the request came in before you were billed we will
                honour the timestamp on the request and refund you.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Here at casttree we understand that sometimes life gets in the
                way. That is why we will honor refund requests on annual and
                semi-annual subscriptions up to 5 days from the date of billing.
                To request a refund please contact us at{" "}
                <a
                  href="mailto:contact@casttree.com"
                  style={{ color: "#ee36ff" }}
                >
                  contact@casttree.com
                </a>
                , use our handy web form.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Monthly subscriptions are ineligible for refunds, except in
                cases of double billing or other clerical errors.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Refund Policy for Casttree events:
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                No Refunds. You may not cancel your events entry at any time.
                Refunds are only issued if required by law. For example, users
                living in the European Union have the right to cancel their
                entry within 14 days of entry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
