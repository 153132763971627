import React, { useEffect, useRef } from 'react';

const JuryLeftToRight = ({ images, scrollSpeed, autoplay = true }) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const slider = sliderRef.current;
        let scrollInterval = null;

        const startScrolling = () => {
            scrollInterval = setInterval(() => {
                slider.scrollLeft += 1;  // Increment to scroll right to left

                // Check if reached the end
                if (slider.scrollLeft >= slider.scrollWidth - slider.clientWidth) {
                    // Reset to the beginning
                    slider.scrollLeft = 0;
                }
            }, scrollSpeed);
        };

        // Determine if it's a mobile device or if there are more than 5 cards
        const isMobile = window.innerWidth <= 768;
        const hasMoreThanFiveCards = images.length > 5;

        // Start scrolling if it's a mobile device or if there are more than 5 cards
        if ((isMobile || hasMoreThanFiveCards) && autoplay) {
            startScrolling();
        }
    }, [autoplay, scrollSpeed, images.length]);

    const handleImageLoad = () => {
        // Determine if it's a mobile device
        const isMobile = window.innerWidth <= 768;

        // If it's a mobile device, start from the first card
        if (isMobile && sliderRef.current) {
            sliderRef.current.scrollLeft = 0;
        }
    };

    return (
        <div className="jury-image-slider landing-page-main web-jc-center" ref={sliderRef}>
            {images.map(({ image, index }) => (
                <div className='' key={index}>
                    <img src={image} alt={`Slide ${index}`} onLoad={handleImageLoad} />
                </div>
            ))}
        </div>
    );
};

export default JuryLeftToRight;