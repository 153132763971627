import axios from "axios";
import { CASTTREE_BASE_URL } from "../helper/video-uploader.helper";
import { APP_CONSTANTS, ENV } from "../const/app.const";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

const CASTTREE_SERVICE_BASE_URL = ENV_VARIABLES.CASTTREE_SERVICE_BASE_URL;
const CASTTREE_MONGODB_BASE_URL = ENV_VARIABLES.CASTTREE_MONGODB_BASE_URL;

// Prod
// const base_url = "https://prodapi.casttree.com";

axios.interceptors.response.use(null, (error) => {
  console.log("interceptor erro is", error);
  if (
    error.response.status === 401 &&
    error.config.url.includes(CASTTREE_BASE_URL)
  ) {
    authService.doLogout();
  }
  throw error;
});

const authService = {
  doLogout: () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("peertube_token");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("user_detail");
    localStorage.removeItem("user_data");
    localStorage.removeItem("fileUploadDetail");
    localStorage.removeItem("peertubeToken");
    localStorage.removeItem("peertubeDefaultChannel");
    localStorage.removeItem("user_otpResponse_data");
    window.location.reload();
  },
  generateOTP: async (phoneNumber) => {
    try {
      const response = await axios.post(
        `${CASTTREE_SERVICE_BASE_URL}/casttree/auth/generate-otp/Phone`,
        {
          country: "IN",
          extension: "+91",
          phoneNumber: phoneNumber,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  otpLogin: async (phoneNumber, otp) => {
    try {
      const response = await axios.post(
        `${CASTTREE_SERVICE_BASE_URL}/casttree/auth/otp-login/Phone`,
        {
          phoneCountryCode: "+91",
          phoneNumber: phoneNumber,
          otp: otp,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getStateList: async (search, skip = 0, limit = 500) => {
    const requestBody = {
      search: "",
      skip: skip,
      limit: limit,
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/state/get-states`,
      requestBody
    );
    return response.data;
  },

  getCityList: async (stateId, skip = 0, limit = 1500) => {
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/city?state=${stateId}&skip=${skip}&limit=${limit}`
    );
    return response.data;
  },

  userDetailUpdate: async (user_id) => {
    const requestBody = {
      user_id: user_id,
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/user/get-user-detail`,
      requestBody
    );
    return response.data;
  },

  updateUserData: async (userData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.patch(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/user/update-user`,
      userData,
      { headers }
    );
    return response.data;
  },

  projectCategoryList: async (search) => {
    const requestBody = {
      search: "",
      category_type: "event",
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/category/get-category-list`,
      requestBody
    );
    return response.data;
  },

  languageLists: async (skip = 0, limit = 1000) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/language?skip=${skip}&limit=${limit}`,
      { headers }
    );
    return response.data;
  },

  getGenreList: async (categoryId) => {
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/genres/get-genres?category=${categoryId}`
    );
    return response;
  },

  saveProject: async (projectData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/project/save-project`,
      projectData,
      { headers }
    );
    return response.data;
  },

  getAwardList: async (categoryId) => {
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/awards/get-awards?category=${categoryId}`
    );
    return response.data;
  },

  saveApplication: async (appData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/application/save-application`,
      appData,
      { headers }
    );
    return response.data;
  },

  getApplicationsList: async (project_id) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/application/get-application/${project_id}`,
      { headers }
    );
    return response.data;
  },

  addNominations: async (nominationData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/nominations/add-nominations`,
      nominationData,
      { headers }
    );
    return response.data;
  },

  getCouponList: async (couponList) => {
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/coupon/get-coupon-list`,
      couponList
    );
    return response.data;
  },

  applyCoupon: async (applyCouponData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/coupon/apply-coupon`,
      applyCouponData,
      { headers }
    );
    return response.data;
  },

  getProjectList: async (skip = 0, limit = 100) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/project/get-projects?skip=${skip}&limit=${limit}&searchAcrossCrew=false`,
      { headers }
    );
    return response.data;
  },

  getProjectDetail: async (project_id) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/project/get-project/${project_id}`,
      { headers }
    );
    return response.data;
  },

  paymentRequestInitiate: async (paymentInitiateData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.patch(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/application/payment`,
      paymentInitiateData,
      { headers }
    );
    return response.data;
  },

  paymentSuccess: async (paymentSuccessData) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/application/success`,
      paymentSuccessData,
      { headers }
    );
    return response.data;
  },

  deleteProject: async (project_id) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.delete(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/project/${project_id}`,
      { headers }
    );
    return response.data;
  },

  getCommunityJoinChennal: async () => {
    const response = await axios.get(
      `${CASTTREE_MONGODB_BASE_URL}/app/casttree-ydwxcuo/endpoint/whatsappGroup`
    );
    return response.data;
  },
};

export default authService;
