import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import appstore from "../assets/app_store.png";
import playstore from "../assets/playstore.png";
import EventNavbarComponent from "./eventNavbar";
import QRCode from "../assets/final_apps_qr.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const DownloadAppScreen = () => {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <div className="" style={{ background: "#000", color: "white" }}>
      <div className="landing-page-main">
        <EventNavbarComponent />
      </div>
      <div className="container mt-5 pt-5">
        <div className="pt-5 lg-px-5 lg-mx-5">
          <div className="head">
            <div className="head-div">
              <div className="">
                <div className="head-div delete-dialog-bg">
                  <Fade direction="right" cascade>
                    <div className="">
                      {/* <div className="">
                        <h1 className="ct-cirka-size-40 mb-2">
                          Casttree
                        </h1>
                        <div className="mt-4 mb-3 pt-3">
                          <p className="ct-text-20 ct-text-gray">
                            You can add new project & nominations or continue
                            application
                          </p>
                        </div>
                      </div> */}
                      <div
                        className="ct-display-flex-bottom"
                        style={{
                        //   borderTopWidth: 1,
                        //   borderTopColor: "#414141",
                        //   borderTopStyle: "solid",
                          paddingTop: 30,
                        }}
                      >
                        <div className="ct-bottom-width-50 pr-3">
                          <p
                            className="ct-text-26 ct-text-gray"
                            style={{ fontWeight: 600 }}
                          >
                            Download{" "}
                            <span className="ct-text-light">
                              {" "}
                              Casttree App{" "}
                            </span>{" "}
                            to keep yourselves updated on your nominations and
                            events. You can:
                          </p>
                          <ul style={{ paddingLeft: 10, fontSize: 22 }}>
                            <li key="1" className="mb-2">
                              <FiberManualRecordIcon
                                style={{
                                  paddingRight: 10,
                                }}
                              />
                              Check application status
                            </li>
                            <li key="2" className="mb-2">
                              <FiberManualRecordIcon
                                style={{
                                  paddingRight: 10,
                                }}
                              />
                              Access to feedbacks from Casttree experts
                            </li>
                            <li key="3" className="mb-2">
                              <FiberManualRecordIcon
                                style={{
                                  paddingRight: 10,
                                }}
                              />
                              Real-time updates on the event
                            </li>
                          </ul>
                        </div>
                        <div className="ct-bottom-width-40">
                          <div className="">
                            <div className="">
                              <p
                                className="ct-text-20 ct-text-gray"
                                style={{ fontWeight: 600 }}
                              >
                                Download the{" "}
                                <span className="ct-text-light">
                                  {" "}
                                  Casttree App{" "}
                                </span>{" "}
                                from the <br /> Play Store or the App Store
                              </p>
                              <div className="ct-display-flex">
                                <div className="">
                                  <a
                                    href="https://fv4b.short.gy/PKKWyx"
                                    target="_blank"
                                  >
                                    <img
                                      src={playstore}
                                      width={150}
                                      style={{ marginRight: 20 }}
                                    />
                                  </a>
                                </div>
                                <div className="">
                                  <a
                                    href="https://fv4b.short.gy/YCG6ws"
                                    target="_blank"
                                  >
                                    <img
                                      src={appstore}
                                      width={150}
                                      style={{ marginTop: 10 }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ct-bottom-width-10">
                          <div className="text-center qr-code-position">
                            <img src={QRCode} width={100} />
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppScreen;
