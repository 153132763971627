import React, { useEffect, useRef } from 'react';

const JuryRightToLeft = ({ images, scrollSpeed, autoplay = true }) => {
    const sliderRef = useRef(null);


    useEffect(() => {
        const slider = sliderRef.current;
        let scrollInterval = null;

        const startScrolling = () => {
            scrollInterval = setInterval(() => {
                slider.scrollLeft -= 1; // Decrement to scroll left to right

                // Check if reached the beginning
                if (slider.scrollLeft <= 0) {
                    // Reset to the maximum scroll position
                    slider.scrollLeft = slider.scrollWidth - slider.clientWidth;
                }
            }, scrollSpeed);
        };


        // const stopScrolling = () => {
        //     clearInterval(scrollInterval);
        // };

        // slider.addEventListener('mouseenter', stopScrolling);
        // slider.addEventListener('mouseleave', startScrolling);


        if (autoplay) {
            startScrolling();
        }


        // return () => {
        //     slider.removeEventListener('mouseenter', stopScrolling);
        //     slider.removeEventListener('mouseleave', startScrolling);
        //     clearInterval(scrollInterval);
        // };
    },[autoplay] [scrollSpeed]);


    return (
        <div className="jury-image-slider landing-page-main web-jc-center" ref={sliderRef}>
            {images.map(({ image, index }) => (
                <div className=''>
                    <img key={index} src={image} alt={`Slide ${index}`} />
                </div>
            ))}
        </div>
    );
};

export default JuryRightToLeft;
