import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import toast, { Toaster } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import appstore from "../assets/app_store.png";
import AwardImg from "../assets/form_image.png";
import playstore from "../assets/playstore.png";
import VideoUploaderHelper, {
  EPeertubeGrantType,
} from "../helper/video-uploader.helper";
import authService from "../services/authService";
import EventNavbarComponent from "./eventNavbar";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { APP_CONSTANTS, ENV } from "../const/app.const";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "../assets/final_apps_qr.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CreateEvent = () => {
  const location = useLocation();

  // User Details Form

  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedDateofBirth, setSelectedDateofBirth] = useState(
    new Date("1990-01-01")
  );
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [genderError, setGenderError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [dobError, setDobError] = useState("");

  const [selectedDateCompletion, setSelectedDateCompletion] = useState();

  const [currentTab, setCurrentTab] = useState(1);
  const [progress, setProgress] = useState(20);
  const [autoprogress, setAutoProgress] = React.useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [showCouponPopUp, setShowCouponPopUp] = React.useState(false);
  const [projectCategoryList, setProjectCategoryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [selectedProjectCategory, setSelectedProjectCategory] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [genreList, setGenreList] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const fileInputRef = useRef(null);
  const [groupAwardList, setGroupAwardList] = useState([]);
  const [individualAwardList, setIndividualAwardList] = useState([]);
  const [projectCategoryError, setProjectCategoryError] = useState("");
  const [projectTitleError, setProjectTitleError] = useState("");
  const [projectDescriptionError, setProjectDescriptionError] = useState("");
  const [genreError, setGenreError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [completionDateError, setCompletionDateError] = useState("");
  const [nominationsList, setNominationsList] = useState();
  const [nomineeList, setNomineeList] = useState([]);
  const [nomineeFormCurrentIdx, setNomineeFormCurrentIdx] = useState(0);
  const [nomineeFullName, setNomineeFullName] = useState("");
  const [nomineeMobileNumber, setNomineeMobileNumber] = useState("");
  const [nomineeEmailID, setNomineeEmailID] = useState("");
  const [nomineeDesc, setNomineeDesc] = useState("");
  const [nomineeFullNameError, setNomineeFullNameError] = useState("");
  const [nomineeMobileNumberError, setNomineeMobileNumberError] = useState("");
  const [nomineeDescError, setNomineeDescError] = useState("");
  const [nomineeEmailIdError, setNomineeEmailIdError] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [filteredIndividualAwards, setFilteredIndividualAwards] = useState([]);
  const [applicationNomineeDatas, setApplicationNomineeDatas] = useState([]);
  const [updatedNominationData, setUpdatedNominationData] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [repeatedIndividualAwards, setRepeatedIndividualAwards] = useState([]);
  const [awardCouponList, setAwardCouponList] = useState([]);
  const [projectLists, setProjectLists] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [loadingProceedToPay, setLoadingProceedToPay] = useState(false);
  const [paymentInitiateResponse, setPaymentInitiateResponse] = useState("");
  const [initProceedToPay, setInitProceedToPay] = useState(false);
  const [nominationsDetails, setNominationsDetails] = useState("");
  const [groupAwardsList, setGroupAwardsList] = useState([]);
  const [showProjectDeleteDialog, setShowProjectDeleteDialog] =
    React.useState(false);
  const uploadStatusText = uploadComplete
    ? "Uploaded"
    : `Uploading ${Math.round(autoprogress)}%`;
  const [peertubeDetails, setPeertubeDetails] = useState({
    peertubeToken: null,
    channelDetail: null,
  });
  const defaultFileUploadDetail = {
    file: null,
    fileName: null,
    doFileUpload: false,
    progress: 0,
    status: "NA",
    reason: "NA",
    mediaId: null,
    uploadedDetails: null,
  };
  const [fileUploaderDetail, setFileUploaderDetail] = useState(
    defaultFileUploadDetail
  );
  const defaultStopUpload = {
    isDisableProgress: true,
    isEnableUpload: false,
  };

  const [stopUploaderDetail, setStopUploaderDetail] =
    useState(defaultStopUpload);

  const stopUploadVideo = async () => {
    fileInputRef.value = "";
    setFileUploaderDetail({ ...defaultFileUploadDetail, doFileUpload: true });
    // setStopUploaderDetail(() => ({
    //   ...stopUploaderDetail,
    //   isDisableProgress: false,
    //   isEnableUpload: true,
    // }));
    // const accessToken = localStorage.getItem("access_token");
    // await VideoUploaderHelper.resumablePeertubeVideo(
    //   userDetail.emailId,
    //   userDetail.phoneNumber,
    //   accessToken
    // );
    // await getPeertubeToken();
  };

  const increaseProgress = () => {
    if (autoprogress < 100) {
      setAutoProgress((prevProgress) => Math.min(prevProgress + 0.5, 100));
    }
  };
  const minDate = new Date("2020-01-01");
  const maxDate = new Date();
  useEffect(() => {
    if (fileUploaderDetail.mediaId && currentTab !== 2) {
      saveProject();
    }
    if (fileUploaderDetail.status === "Failed") {
      alert("Failed to upload. Pls try again later.");
    }
  }, [fileUploaderDetail.mediaId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    const tabNumber = parseInt(tabParam);
    if (!isNaN(tabNumber) && tabNumber >= 1 && tabNumber <= 3) {
      setCurrentTab(tabNumber);
    }
  }, [location.search]);

  const navigateToAwardChoose = () => {
    setNomineeFullName("");
    setNomineeMobileNumber("");
    setNomineeEmailID("");
    setNomineeDesc("");
    setCheckboxChecked(false);
    setNomineeFullNameError("");
    setNomineeMobileNumberError("");
    setNomineeEmailIdError("");
    setNomineeDescError("");
    handleTabChange(4);
    setNomineeFormCurrentIdx(0);
    setProgress((prevProgress) => prevProgress - 10);
  };

  const navigateToProjectForm = () => {
    handleTabChange(2);
    setProgress((prevProgress) => prevProgress - 10);
  };

  const navigateToUserForm = () => {
    handleTabChange(1);
    setProgress((prevProgress) => prevProgress - 10);
  };

  // User Detail
  const getUserDetails = async () => {
    const user_id = localStorage.getItem("user_id");
    const userDetail = await authService.userDetailUpdate(user_id);
    setMobileNumber(userDetail?.phoneNumber);
    setFullName(userDetail?.userName);
    setEmail(userDetail?.emailId);
    setSelectedGender(userDetail?.gender);
    setSelectedDateofBirth(
      userDetail?.dateOfBirth ? new Date(userDetail.dateOfBirth) : null
    );
    const stateId = userDetail?.state;
    const stateIdCity = userDetail?.state?._id;
    if (stateId) {
      setSelectedState(stateId);
      getCities(stateIdCity);
    }
    setSelectedCity(userDetail?.city);
    if (userDetail?.userName == null) {
      navigate(`/event-create?tab=1`);
      return;
    }
  };

  // Create User
  const updateUserDetails = async () => {
    const userLoginOrgDetail = localStorage.getItem("user_otpResponse_data");
    let isValid = true;
    try {
      if (!selectedGender) {
        setGenderError("Please select gender"); // Set error message
        isValid = false;
      }
      // if (!validateEmail(email)) {
      //   setEmailError("Please enter a valid email address"); // Set error message
      //   isValid = false;
      // }
      if (!fullName) {
        setFullNameError("Please enter a Fullname"); // Set error message
        isValid = false;
      }
      if (!selectedState || !selectedState._id) {
        setStateError("Please select state"); // Set error message
        isValid = false;
      }
      if (!selectedCity || !selectedCity._id) {
        setCityError("Please select city"); // Set error message
        isValid = false;
      }
      if (!selectedDateofBirth) {
        setDobError("Please select date of birth"); // Set error message
        isValid = false;
      }

      if (!isValid) return;
      const userData = {
        organizationId: userLoginOrgDetail.trim().replace(/"/g, ""),
        emailId: email,
        userName: fullName,
        city: selectedCity?._id,
        state: selectedState?._id,
        gender: selectedGender,
        phoneCountryCode: "+91",
        phoneNumber: mobileNumber,
        dateOfBirth: selectedDateofBirth?.toISOString(),
      };
      const updatedUserDetail = await authService.updateUserData(userData);
      localStorage.setItem("user_detail", JSON.stringify(userData));
      toast.success("User Updated Successfully");
      nextTab();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.log("User Create API Error", error);
    }
  };

  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    setFullNameError("");
  };
  const handleEmailChange = (e) => {
    const emailId = e.target.value;
    setEmail(emailId);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailId && !emailPattern.test(emailId)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  // const handleEmailChange = (e) => {
  //   const emailId = e.target.value;
  //   setEmail(emailId);
  //   setEmailError("");
  // };

  // State API
  const getStates = async (search = "", skip = 0, limit = 500) => {
    const response = await authService.getStateList({
      search: search,
      skip: skip,
      limit: limit,
    });
    setStateList(response?.data);
  };

  // City API
  const getCities = async (stateId) => {
    const response = await authService.getCityList(stateId);
    setCityList(response?.data);
  };

  // User Update API
  const userDetailUpdate = async (user_id) => {
    const userDetail = await authService.userDetailUpdate(user_id);
  };

  // User get UserId in Local
  const getUserIdFromLocalStorage = () => {
    const user_id = localStorage.getItem("user_id");
    userDetailUpdate(user_id);
  };

  // State Change
  const handleStateChange = (event, newValue) => {
    if (newValue) {
      setSelectedState(newValue);
      setSelectedCity(null);
      getCities(newValue._id);
      setCityError("");
      setStateError("");
    }
  };

  // City Change
  const handleCityChange = (event, newValue) => {
    if (newValue) {
      setSelectedCity(newValue);
      setCityError("");
    }
  };

  // DOB Calculator
  const calculateAge = (dob) => {
    if (!dob) return null; // If no DOB selected, return null
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Email Regular Expression
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Date Change
  const handleDOBChange = (date) => {
    setSelectedDateofBirth(date);
    setDobError("");
  };
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
    setGenderError("");
  };

  // Create User Submittion
  const saveUser = async () => {
    await updateUserDetails();
    await initPeertubeData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Project Details
  const getProjectsList = async () => {
    const response = await authService.getProjectList();
    setProjectLists(response?.data);
  };

  const navigateToProjectCreate = () => {
    handleTabChange(2);
  };

  // Project category List
  const getProjectCategoryList = async (search = "") => {
    const response = await authService.projectCategoryList({
      search: search,
    });
    setProjectCategoryList(response?.data);
  };

  // Change Project category
  const handleProjectCategoryChange = async (event, newValue) => {
    if (newValue) {
      setSelectedProjectCategory(newValue);
      getGenre(newValue?._id);
      getAwardsList(newValue?._id);
      setProjectCategoryError("");
      setSelectedGenre("");
    }
  };

  const getLanguageList = async () => {
    const response = await authService.languageLists();
    setLanguageList(response?.data);
  };

  const handleLanguageChange = async (event, newValue) => {
    if (newValue) {
      setSelectedLanguage(newValue);
      setLanguageError("");
    }
  };

  const getGenre = async (categoryId) => {
    const response = await authService.getGenreList(categoryId);
    setGenreList(response?.data);
  };

  // Genre List
  const handleGenreChange = (event) => {
    const selectedGenreId = event?.target?.value;
    setSelectedGenre(selectedGenreId);
    setGenreError("");
  };

  const handleProjectTitleChange = (e) => {
    const title = e.target.value;
    setProjectTitle(title);
    setProjectTitleError("");
  };

  const handleProjectDescriptionChange = (e) => {
    const description = e.target.value;
    setProjectDescription(description);
    setProjectTitleError("");
  };

  const handleDateCompletionChange = (date) => {
    setSelectedDateCompletion(date);
    setCompletionDateError("");
  };

  const validateFormProject = () => {
    let isValid = true;

    // Reset error states
    setProjectCategoryError("");
    setProjectTitleError("");
    setProjectDescriptionError("");
    setGenreError("");
    setCompletionDateError("");
    setLanguageError("");

    // Validate project category
    if (!selectedProjectCategory) {
      setProjectCategoryError("Please select project category");
      isValid = false;
    }
    // Validate project title
    if (!projectTitle) {
      setProjectTitleError("Please enter project title");
      isValid = false;
    }
    // Validate project description
    if (!projectDescription) {
      setProjectDescriptionError("Please enter project description");
      isValid = false;
    }
    // Validate genre
    if (!selectedGenre) {
      setGenreError("Please select genre");
      isValid = false;
    }
    if (!selectedLanguage) {
      setLanguageError("Please select language");
      isValid = false;
    }
    // Validate completion date
    if (!selectedDateCompletion) {
      setCompletionDateError("Please select completion date");
      isValid = false;
    }
    return isValid;
  };
  // Create Project

  const saveProject = async () => {
    const isValid = validateFormProject();
    const media = [];
    if (!fileUploaderDetail.mediaId) {
      alert("Video file not exist, Try to re/upload to save!");
      return;
    }
    media.push({
      type: "ProjectContent",
      media_id: fileUploaderDetail.mediaId,
    });
    if (isValid) {
      const projectData = {
        category: selectedProjectCategory?._id,
        title: projectTitle,
        description: projectDescription,
        status: "Active",
        documentStatus: "Draft",
        completionDate: selectedDateCompletion?.toISOString(),
        genre: selectedGenre,
        language: selectedLanguage?._id,
        media: media,
      };
      if (projectId) {
        // Update project
        projectData.project_id = projectId;
        await authService.saveProject(projectData);
        // getNominationsDetail(projectId);
        handleTabChange(4);
        window.scrollTo({ top: 0, behavior: "smooth" });
        toast.success("Project updated successfully");
      } else {
        // Create project
        const projectDetails = await authService.saveProject(projectData);
        const projectId = projectDetails?._id;
        setProjectId(projectId);
        handleTabChange(4);
        window.scrollTo({ top: 0, behavior: "smooth" });
        toast.success("Project created successfully");
      }
    }
  };

  const handleTabChange = (tabNumber) => {
    setCurrentTab(tabNumber);
  };

  const editProject = async (selectedProjectId) => {
    const projectDetail = await authService.getProjectDetail(selectedProjectId);
    setSelectedProjectCategory(projectDetail?.data?.category);
    setProjectTitle(projectDetail?.data?.title);
    setProjectDescription(projectDetail?.data?.description);
    setSelectedGenre(projectDetail?.data?.genre?._id);
    setSelectedLanguage(projectDetail?.data?.language);
    if (projectDetail?.data?.media && projectDetail.data.media?.length) {
      const projectContent = projectDetail.data.media.find(
        (e) => e.type == "ProjectContent"
      );
      if (projectContent && projectContent.media_id._id) {
        setFileUploaderDetail({
          ...defaultFileUploadDetail,
          status: "Uploaded",
          progress: 100,
          doFileUpload: true,
          fileName: projectContent.media_id.file_name,
          mediaId: projectContent.media_id._id,
          uploadedDetails: {
            video: {
              uuid: projectContent.media_id?.location?.split(
                "videos/embed/"
              )[1],
            },
          },
        });
      } else {
        setFileUploaderDetail({ ...defaultFileUploadDetail });
      }
    } else {
      setFileUploaderDetail({ ...defaultFileUploadDetail });
    }
    const completionDate = projectDetail?.data?.completionDate;
    if (completionDate && !isNaN(new Date(completionDate).getTime())) {
      setSelectedDateCompletion(new Date(completionDate));
    } else {
      console.error("Invalid completion date:", completionDate);
    }
    setProjectId(projectDetail?.data?._id);
    getNominationsDetail(projectDetail?.data?._id);
    getGenre(projectDetail?.data?.category?._id);
    getAwardsList(projectDetail?.data?.category?._id);
    handleTabChange(4);
  };

  const handleUploadFileClick = () => {
    setFileUploaderDetail((fileUploaderDetail) => ({
      ...fileUploaderDetail,
      doFileUpload: true,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Award List
  const getAwardsList = async (categoryId) => {
    const response = await authService.getAwardList(categoryId);
    const groupAwards = [];
    const individualAwards = [];
    response?.data.forEach((award) => {
      if (award.awardType === "Group")
        groupAwards.push({ ...award, checked: false });
      else individualAwards.push({ ...award, count: 0 });
    });
    setGroupAwardList(groupAwards);
    setIndividualAwardList(individualAwards);
    if (projectId) {
      getNominationsDetail(projectId);
    }
  };

  // Video Upload Progress Bar
  useEffect(() => {
    if (autoprogress === 100) {
      setUploadComplete(true);
    }
    const timer = setInterval(increaseProgress, 100);
    return () => clearInterval(timer);
  }, [autoprogress]);

  const backgroundPosition = `${autoprogress * 0.1 * 10}%`;

  const handleGroupAwardItemClick = (award) => {
    if (selectedCoupon && award.checked) {
      setSelectedCoupon(null);
    }
    setGroupAwardList(
      groupAwardList.map((e) => {
        if (e._id == award._id) e.checked = !e.checked;
        return e;
      })
    );
  };

  const incIndAwardCount = (indAward) => {
    setIndividualAwardList((indAwardList) =>
      indAwardList.map((award) => {
        if (award._id === indAward._id) {
          if (award.count < 9) {
            return { ...award, count: award.count + 1 };
          } else {
            return award;
          }
        }
        return award;
      })
    );
  };

  const decIndAwardCount = (indAward) => {
    if (selectedCoupon) {
      setSelectedCoupon(null);
    }
    setIndividualAwardList((indAwardList) =>
      indAwardList.map((award) => {
        if (award._id == indAward._id) {
          if (award.count == 0) return award;
          return { ...award, count: award.count - 1 };
        }
        return award;
      })
    );
  };

  const calculateGrandTotal = () => {
    let totalWithoutDiscount = 0;
    const individualTotal = individualAwardList?.reduce(
      (total, award) => total + (parseFloat(award.count * award.price) || 0),
      0
    );
    const groupTotal = groupAwardList.reduce(
      (total, award) => total + (award.checked ? parseFloat(award.price) : 0),
      0
    );
    totalWithoutDiscount = individualTotal + groupTotal;
    if (selectedCoupon) {
      const discount = parseFloat(selectedCoupon.discountValue) || 0;
      return totalWithoutDiscount - discount;
    }
    return totalWithoutDiscount;
  };

  const calculateTotal = () => {
    let totalAmount = 0;
    const individualTotal = individualAwardList?.reduce(
      (total, award) => total + (parseFloat(award.count * award.price) || 0),
      0
    );
    const groupTotal = groupAwardList.reduce(
      (total, award) => total + (award.checked ? parseFloat(award.price) : 0),
      0
    );
    totalAmount = individualTotal + groupTotal;
    return totalAmount;
  };

  // Update Application
  const saveApplication = async () => {
    try {
      const awardsData = [];
      groupAwardList.forEach((award) => {
        if (award.checked)
          awardsData.push({
            awardId: award._id,
            type: "group",
            count: 1,
          });
      });

      individualAwardList.forEach((award) => {
        if (award.count)
          awardsData.push({
            awardId: award._id,
            type: "single",
            count: award.count,
          });
      });

      const appData = {
        projectId: projectId,
        total: calculateTotal(),
        grandTotal: calculateGrandTotal(),
        appStatus: "Draft",
        coupon: selectedCoupon ? selectedCoupon._id : null,
        awards: awardsData,
      };
      if (applicationId) {
        appData.application_id = applicationId;
        handleTabChange(5);
        await authService.saveApplication(appData);
        setProgress((prevProgress) => prevProgress + 20);
        toast.success("Application updated successfully");
        setApplicationId(applicationId);
        window.scrollTo({ top: 0, behavior: "smooth" });
        await getNominationsList(projectId);
      } else {
        const applicationDetails = await authService.saveApplication(appData);
        const applicationId = applicationDetails?.data?._id;
        setProgress((prevProgress) => prevProgress + 20);
        setApplicationId(applicationId);
        handleTabChange(5);
        window.scrollTo({ top: 0, behavior: "smooth" });
        await getNominationsList(projectId);
      }
    } catch (error) {
      console.log("Save Application API Error", error);
    }
  };

  const nextTab = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentTab((prevTab) => (prevTab < 8 ? prevTab + 1 : prevTab));
    setProgress((prevProgress) => prevProgress + 20);
  };

  const handleApplyCoupon = () => {
    setShowCouponPopUp(true);
    getCouponLists();
  };
  const handleCloseCoupon = () => {
    setShowCouponPopUp(false);
  };

  useEffect(() => {
    getProjectsList();
    getUserIdFromLocalStorage();
    getUserDetails();
    getStates();
    getProjectCategoryList();
    getLanguageList();
    initPeertubeData();
  }, []);
  useEffect(() => {
    if (initProceedToPay) {
      handleProceedToPay(applicationId);
    }
  }, [initProceedToPay]);
  // Coupon
  const getCouponLists = async () => {
    const awardsData = [];

    groupAwardList.forEach((award) => {
      if (award.checked)
        awardsData.push({
          awardId: award._id,
          type: "group",
          count: 1,
        });
    });

    individualAwardList.forEach((award) => {
      if (award.count)
        awardsData.push({
          awardId: award._id,
          type: "single",
          count: award.count,
        });
    });

    const couponData = {
      awards: awardsData,
      totalAmount: calculateTotal().toString(),
    };
    const couponList = await authService.getCouponList(couponData);
    setAwardCouponList(couponList?.data);
  };

  const couponApply = async (selectedCoupon) => {
    setSelectedCoupon(selectedCoupon);
    setShowCouponPopUp(false);
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
  };

  // Nominations Tab

  // Prefill for Update Awards
  const getNominationsDetail = async (projectDetail) => {
    try {
      const response = await authService.getApplicationsList(projectDetail);
      setNominationsList(response?.data);
      setNomineeList(response?.data?.awards);
      setRepeatedIndividualAwards(response?.data?.awards);
      setApplicationId(response?.data?._id);
      const dataExist = response?.data ? true : false;
      if (dataExist) {
        setGroupAwardList((groupAwardList) => {
          return groupAwardList.map((groupAward) => {
            const awardSelected = response.data.awards?.some(
              (e) => e.awardId._id === groupAward._id
            );
            if (awardSelected) return { ...groupAward, checked: true };
            return groupAward;
          });
        });
        setIndividualAwardList((individualAwardList) => {
          return individualAwardList.map((individualAward) => {
            const awardSelected = response.data.awards.find(
              (e) => e.awardId._id === individualAward._id
            );
            if (awardSelected)
              return { ...individualAward, count: awardSelected.count };
            return individualAward;
          });
        });
      }
    } catch (error) {
      console.log("Get Application", error);
      handleTabChange(2);
    }
  };

  const getNominationsList = async (selectedProjectId) => {
    const response = await authService.getApplicationsList(selectedProjectId);
    setNominationsList(response?.data);
    setNomineeList(response?.data.awards);
    setNominationsDetails(response?.data);
    const filteredIndividualAwards = response?.data?.awards.filter(
      (award) => award?.awardId?.awardType === "Individual"
    );
    const groupAwards = response?.data?.awards.filter(
      (award) => award.awardId.awardType === "Group"
    );
    setGroupAwardsList(groupAwards);
    console.log("groupAwards", groupAwards);

    setFilteredIndividualAwards(filteredIndividualAwards);
    let repeatedIndividualAwards = [];
    filteredIndividualAwards.forEach((award) => {
      for (let i = 0; i < award.count; i++) {
        repeatedIndividualAwards.push(award);
      }
    });
    setRepeatedIndividualAwards(repeatedIndividualAwards);
    setApplicationNomineeDatas(Array(repeatedIndividualAwards.length).fill({}));
  };

  const saveNomineeData = async (submitApplication) => {
    let isValid = true;
    const user_details = JSON.parse(localStorage.getItem("user_detail"));
    const mobileNumber = user_details?.phoneNumber || "";
    const selectedAward = repeatedIndividualAwards[nomineeFormCurrentIdx];
    const awardId = selectedAward?.awardId?._id;
    if (!nomineeFullName) {
      setNomineeFullNameError("Please enter a Fullname");
      isValid = false;
    }
    if (!nomineeMobileNumber || !isValidNumber) {
      setNomineeMobileNumberError("Please enter a valid Mobile Number");
      isValid = false;
    }
    if (!checkboxChecked && nomineeMobileNumber === mobileNumber) {
      setIsValidNumber(false);
      setNomineeMobileNumberError("Please enter nominee phone number");
      isValid = false;
    }
    if (!nomineeDesc) {
      setNomineeDescError("Please enter a Description");
      isValid = false;
    }
    if (nomineeEmailID && nomineeEmailIdError) {
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const currentNomineeData = {
      projectId: projectId,
      applicationId: applicationId,
      awardId: awardId,
      nomineeName: nomineeFullName,
      nomineePhoneNumber: nomineeMobileNumber,
      nomineeDescription: nomineeDesc,
      // status: "Draft",
      userDetail: {
        phoneCountryCode: "+91",
        phoneNumber: nomineeMobileNumber,
        userName: nomineeFullName,
      },
    };

    if (nomineeEmailID) {
      currentNomineeData.nomineeEmail = nomineeEmailID;
      currentNomineeData.userDetail.emailId = nomineeEmailID;
    }

    setApplicationNomineeDatas((curApplicationNomineeDatas) => {
      const updatedApplicationNomineeDatas = [...curApplicationNomineeDatas];
      updatedApplicationNomineeDatas[nomineeFormCurrentIdx] =
        currentNomineeData;
      return updatedApplicationNomineeDatas;
    });

    if (submitApplication) setInitProceedToPay(true);
    setNomineeFormCurrentIdx((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex < repeatedIndividualAwards.length) {
        setNomineeFullName(
          applicationNomineeDatas[nextIndex]?.nomineeName || ""
        );
        setNomineeMobileNumber(
          applicationNomineeDatas[nextIndex]?.nomineePhoneNumber || ""
        );
        setNomineeEmailID(
          applicationNomineeDatas[nextIndex]?.nomineeEmail || ""
        );
        setNomineeDesc(
          applicationNomineeDatas[nextIndex]?.nomineeDescription || ""
        );
        setCheckboxChecked(false);
        setNomineeFullNameError("");
        setNomineeMobileNumberError("");
        setNomineeEmailIdError("");
        setNomineeDescError("");
        return nextIndex;
      } else {
        return prevIndex;
      }
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (applicationId) {
  //     handleProceedToPay();
  //   }
  // }, [applicationId]);

  const withoutIndividualAward = async () => {
    try {
      const awardsData = [];
      groupAwardList?.forEach((award) => {
        if (award.checked)
          awardsData?.push({
            awardId: award?._id,
            type: "group",
            count: 1,
          });
      });

      individualAwardList?.forEach((award) => {
        if (award?.count)
          awardsData?.push({
            awardId: award?._id,
            type: "single",
            count: award.count,
          });
      });

      const appData = {
        projectId: projectId,
        total: calculateTotal(),
        grandTotal: calculateGrandTotal(),
        appStatus: "Draft",
        coupon: selectedCoupon ? selectedCoupon._id : null,
        awards: awardsData,
      };
      let newApplicationId;
      if (applicationId) {
        appData.application_id = applicationId;
        await authService.saveApplication(appData);
        toast.success("Application updated successfully");
        await getNominationsList(projectId);
        newApplicationId = applicationId;
        // setApplicationId(applicationId);
        // handleProceedToPay();
      } else {
        const applicationDetails = await authService.saveApplication(appData);
        newApplicationId = applicationDetails?.data?._id;
        await getNominationsList(projectId);
        setApplicationId(applicationId);
        // handleProceedToPay();
        setApplicationId(newApplicationId);
      }
      // if (initProceedToPay) {
      handleProceedToPay(newApplicationId);
      // }
    } catch (error) {
      console.log("Save Application API Error", error);
    }
  };

  const handleProceedToPay = async (applicationId) => {
    setLoadingProceedToPay(true);
    try {
      const formattedNominations = applicationNomineeDatas.map((nominee) => ({
        ...nominee,
      }));
      razorPayPaymentInitiate(applicationId, formattedNominations);
    } catch (error) {
      console.error("Error for adding nominations:", error);
      setInitProceedToPay(false);
    }
  };

  const razorPayPaymentInitiate = async (
    applicationId,
    formattedNominations
  ) => {
    console.log("applicationId3", applicationId);
    const user_id = localStorage.getItem("user_id");
    const paymentInitiateData = {
      application_id: applicationId,
      amount: calculateTotal(),
      coupon: selectedCoupon?._id,
      discountAmount: parseFloat(selectedCoupon.discountValue),
      userId: user_id,
      totalAmount: calculateGrandTotal(),
      nominations: formattedNominations,
    };

    try {
      setLoadingProceedToPay(true);
      const paymentInitiateResponse = await authService.paymentRequestInitiate(
        paymentInitiateData
      );
      setPaymentInitiateResponse(paymentInitiateResponse);
      const orderId = paymentInitiateResponse?.order;
      const rzp = new window.Razorpay({
        key: ENV_VARIABLES.RAZOR_PAY_KEY,
        name: "Casttree",
        order_id: orderId,
        theme: {
          color: "#3399cc",
        },
        handler: function (response) {
          return new Promise(async (resolve, reject) => {
            const paymentSuccessData = {
              application_id: applicationId,
              // coupon: selectedCoupon?._id,
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_signature: response?.razorpay_signature,
              // nominations: formattedNominations,
            };

            try {
              const paymentSuccessResponse = await authService.paymentSuccess(
                paymentSuccessData
              );
              resolve();
              navigate(`/eventssuccessfully?projectId=${projectId}`);
              window.scrollTo({ top: 0, behavior: "smooth" });
            } catch (error) {
              console.log("Payment failed or canceled!", error);
              setLoadingProceedToPay(false);
              setInitProceedToPay(false);
              reject(error);
            }
          });
        },
        modal: {
          ondismiss: function () {
            setLoadingProceedToPay(false); // Enable the button if the payment window is closed
            setInitProceedToPay(false);
          },
        },
      });
      rzp.open();
    } catch (error) {
      console.error("Error for adding nominations:", error);
      setLoadingProceedToPay(false);
    }
  };

  const handleNomineeBack = () => {
    const prevIndex = nomineeFormCurrentIdx - 1;
    if (prevIndex >= 0) {
      setNomineeFormCurrentIdx(prevIndex);
      const prevNomineeData = applicationNomineeDatas[prevIndex];
      if (!checkboxChecked) {
        setNomineeFullName(prevNomineeData?.nomineeName || "");
        setNomineeMobileNumber(prevNomineeData?.nomineePhoneNumber || "");
        setNomineeEmailID(prevNomineeData?.nomineeEmail || "");
        setNomineeDesc(prevNomineeData?.nomineeDescription || "");
        setNomineeFullNameError("");
        setNomineeMobileNumberError("");
        setNomineeEmailIdError("");
        setNomineeDescError("");
      }
    }
  };

  const handleNomineeFullNameChange = (e, index) => {
    const nomineeName = e.target.value;
    const updatedNomineeNames = [...nomineeFullName];
    updatedNomineeNames[index] = nomineeName;
    setNomineeFullName(nomineeName);
    setNomineeFullNameError("");
  };

  const handleNomineeMobileNumberChange = (e) => {
    const nomineePhoneNumber = e.target.value;
    const isValid = /^[6-9]\d{9}$/.test(nomineePhoneNumber);
    setIsValidNumber(isValid);
    setNomineeMobileNumber(nomineePhoneNumber);
    if (!isValid) {
      setNomineeMobileNumberError("Invalid phone number");
    } else {
      setNomineeMobileNumberError("");
    }
  };

  const handleNomineeEmailIdChange = (e) => {
    const email = e.target.value;
    setNomineeEmailID(email);
    // Email validation using regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailPattern.test(email)) {
      setNomineeEmailIdError("Please enter a valid email address");
    } else {
      setNomineeEmailIdError("");
    }
  };

  const handleNomineeDescChange = (e) => {
    const nomineeDescription = e.target.value;
    setNomineeDesc(nomineeDescription);
    setNomineeDescError("");
  };

  const handleNomineeDetailPrefill = (index, e) => {
    const { checked } = e.target;
    const user_details = JSON.parse(localStorage.getItem("user_detail"));
    setCheckboxChecked(checked);
    if (!checked) {
    }

    if (checked) {
      setNomineeFullName(user_details?.userName || "");
      const phoneNumber = user_details?.phoneNumber || "";
      setNomineeMobileNumber(phoneNumber);
      const isValid = phoneNumber.match(/^\d{10}$/);
      setIsValidNumber(isValid);
      setNomineeFullNameError("");
      setNomineeMobileNumberError("");
      setNomineeEmailIdError("");
      if (!isValid) {
        setNomineeMobileNumberError("Please enter a valid Mobile Number");
      }
      setNomineeEmailID(user_details?.emailId || "");
    } else {
      setNomineeFullNameError("");
      setNomineeMobileNumberError("");
      setNomineeEmailIdError("");
      setNomineeFullName("");
      setNomineeMobileNumber("");
      setNomineeEmailID("");
    }
  };
  const getPeertubeToken = async () => {
    try {
      const userDetail = JSON.parse(localStorage.getItem("user_detail"));
      const accessToken = localStorage.getItem("access_token");
      if (!userDetail?.phoneNumber || !userDetail?.emailId) return;
      const loginRes = await VideoUploaderHelper.getPeertubeToken(
        userDetail?.phoneNumber,
        accessToken,
        EPeertubeGrantType.password,
        null
      );
      const peertubeToken = {
        access_token: loginRes.access_token,
        expires_in: Date.now() + loginRes.expires_in * 1000,
        refresh_token: loginRes.refresh_token,
        refresh_token_expires_in:
          Date.now() + loginRes.refresh_token_expires_in * 1000,
      };
      localStorage.setItem("peertubeToken", JSON.stringify(peertubeToken));
    } catch (err) {
      throw err;
    }
  };
  const initPeertubeData = async () => {
    if (!localStorage.getItem("peertubeToken")) {
      try {
        await getPeertubeToken();
      } catch (loginError) {
        try {
          // TODO: Create user code will be added here
          const userDetail = JSON.parse(localStorage.getItem("user_detail"));
          const accessToken = localStorage.getItem("access_token");
          await VideoUploaderHelper.createPeertubeUser(
            userDetail.phoneNumber,
            accessToken
          );
          await getPeertubeToken();
        } catch (createAndLoginError) {
          return alert("Failed to initiate upload config");
        }
      }
    }
    getPeertubeChannel();
  };
  const getPeertubeChannel = async () => {
    if (localStorage.getItem("peertubeDefaultChannel")) {
      setPeertubeDetails((sPeertubeDetails) => ({
        ...sPeertubeDetails,
        channelDetail: JSON.parse(
          localStorage.getItem("peertubeDefaultChannel")
        ),
      }));
    } else {
      const userDetail = JSON.parse(localStorage.getItem("user_detail") || {});
      if (!userDetail?.phoneNumber || !userDetail?.emailId) return;
      const peertubeToken = JSON.parse(localStorage.getItem("peertubeToken"));
      const videoChannelRes = await VideoUploaderHelper.getVideoChannel(
        peertubeToken?.access_token,
        `${userDetail?.phoneNumber}_user_default_channel`
      );
      if (videoChannelRes) {
        setPeertubeDetails((sPeertubeDetails) => ({
          ...sPeertubeDetails,
          channelDetail: videoChannelRes,
        }));
        localStorage.setItem(
          "peertubeDefaultChannel",
          JSON.stringify(videoChannelRes)
        );
      }
    }
  };
  async function onFileSelect(event) {
    const peertubeToken = JSON.parse(localStorage.getItem("peertubeToken"));
    if (
      !peertubeToken ||
      !peertubeToken?.access_token ||
      !peertubeDetails.channelDetail?.id
    ) {
      return alert(
        "Upload config not exist. Pls try after some time or refresh the page"
      );
    }

    const selFile = event.target.files[0];
    const maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes

    if (selFile) {
      if (selFile.size > maxFileSize) {
        return alert(
          "File size exceeds the 2GB limit. Please upload a smaller file."
        );
      }

      setFileUploaderDetail((fileUploaderDetail) => ({
        ...fileUploaderDetail,
        status: "Uploading",
        file: selFile,
        fileName: selFile.name,
      }));

      const ctToken = localStorage.getItem("access_token");
      await VideoUploaderHelper.uploadFileInChunk(
        peertubeDetails.channelDetail?.id,
        selFile,
        setFileUploaderDetail,
        ctToken
      );
    } else {
      setFileUploaderDetail((fileUploaderDetail) => ({
        ...fileUploaderDetail,
        ...defaultFileUploadDetail,
      }));
    }
  }

  function handleUploadFileLater() {
    setFileUploaderDetail((fileUploaderDetail) => ({
      ...fileUploaderDetail,
      doFileUpload: false,
    }));
  }

  const [projectIdToDelete, setProjectIdToDelete] = useState(null);

  const removeProject = async (projectId) => {
    await authService.deleteProject(projectId);
    getProjectsList();
    toast.success("Project removed successfully");
  };

  const handleProjectDialogClose = () => {
    setShowProjectDeleteDialog(false);
    setProjectIdToDelete(null);
  };

  const openProjectDeleteDialog = (projectId) => {
    setProjectIdToDelete(projectId);
    setShowProjectDeleteDialog(true);
  };

  // PDF Download

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    doc.setTextColor("#4caf50");

    // Title
    doc.setFontSize(30);
    doc.setFont("helvetica", "bold");
    doc.text("Application Confirmed", 20, 20);

    // Content
    doc.setFontSize(15);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");
    doc.text(
      "You have successfully registered for Casttree Awards 2024",
      20,
      30
    );

    // Your nominations
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000");
    doc.text("Your Nominations", 20, 50);

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");

    // Summary of nominations
    doc.text("Summary of your nominations registered for the event", 20, 58);

    // Render group awards list
    let y = 70;
    groupAwardsList.forEach((award, index) => {
      doc.text(`${award.awardId.title}`, 20, y);
      y += 10;
    });

    // Render individual nominations
    nominationsDetails?.nominations?.forEach((nomination, index) => {
      doc.text(
        `${nomination.awardId.title} - ${nomination.nomineeName}`,
        20,
        y
      );
      y += 10;
    });

    doc.setLineWidth(0.5);
    doc.setDrawColor("#EEE");
    doc.line(20, y + 5, doc.internal.pageSize.width - 20, y + 5);

    // Additional Information
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000");
    doc.text("Additional Information", 20, 115);

    doc.setFontSize(12);
    // doc.setFontStyle("normal");
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");

    // Number of nominations
    doc.text("6 admits", 20, 123);
    // doc.text(
    //   `${nominationsCount} admit${nominationsCount !== 1 ? "s" : ""}`,
    //   20,
    //   123
    // );

    // Event Date
    const imgDataCalander =
      "https://tecxsprts3.s3.amazonaws.com/1718282454532-calendar.png";
    const imgWidth = 8;
    const imgHeight = 8;
    const textX = 20 + imgWidth + 2; // Adjust x position to leave space between image and text
    const textY = 135; // Adjust y position as needed

    // Add image
    doc.addImage(
      imgDataCalander,
      "PNG",
      20,
      textY - imgHeight / 2,
      imgWidth,
      imgHeight
    );

    // Add text next to image
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Event Date", textX, textY);

    // Event Date value
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("12 - 13 September 2024", textX, textY + 8);

    // Event Location
    const imgDataLocation =
      "https://tecxsprts3.s3.amazonaws.com/1718283555726-direction.png";
    const locationImgWidth = 8;
    const locationImgHeight = 8;
    const locationTextX = 20 + locationImgWidth + 2; // Adjust x position to leave space between image and text
    const locationTextY = 155; // Adjust y position as needed

    // Add image
    doc.addImage(
      imgDataLocation,
      "PNG",
      20,
      locationTextY - locationImgHeight / 2,
      locationImgWidth,
      locationImgHeight
    );

    // Add text next to image
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Event Location", locationTextX, locationTextY);

    // Event Location value
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Vels University, Chennai", locationTextX, locationTextY + 8);
    // y += 30;

    // Logo
    const imgData =
      "https://tecxsprts3.s3.amazonaws.com/1718284052276-ct_trans.01c0c442dcd2125a021c.png";
    const logoWidth = 30; // Width of the logo
    const logoHeight = 9; // Height of the logo
    const logoX = doc.internal.pageSize.width - logoWidth - 22; // Center the logo horizontally
    const logoY = pageHeight - logoHeight - 20; // Position 20 units from the bottom
    doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

    // Save the PDF
    doc.save("confirmation.pdf");
  };

  const downloadApplication = async (projectIdData) => {
    const response = await authService.getApplicationsList(projectIdData);
    setNominationsDetails(response?.data);
    const groupAwards = response?.data?.awards.filter(
      (award) => award.awardId.awardType === "Group"
    );
    setGroupAwardsList(groupAwards);
    generatePDF();
    console.log("groupAwards", groupAwards);
  };

  return (
    <div className="" style={{ background: "#000", color: "white" }}>
      <div className="landing-page-main">
        <EventNavbarComponent />
      </div>
      <div className="container mt-0">
        <div
          style={{
            position: "relative",
            bottom: "-5px",
            left: 0,
            width: "100%",
            height: "3px",
            background: `linear-gradient(to right, #EE35FF 0%, #FF8761 ${progress}%, #3D3D3D ${progress}%, #3D3D3D 100%)`, // Gradient for the border
            zIndex: 1,
          }}
        ></div>
        <div className="pt-5 lg-px-5 lg-mx-5">
          <div className="head">
            <div className="head-div">
              <Fade direction="down" cascade>
                {/* Tell us about you Section */}
                <div className="">
                  {currentTab === 1 && (
                    <div className="ct-d-flex-justify-center-web ct-flex-row mb-5 pb-4">
                      <div className="event-first-section">
                        <div className="">
                          <div className="ct-text-left">
                            <h1 className="ct-cirka-size-30">
                              Tell us about you
                            </h1>
                            <div className="mt-2 mb-3">
                              <p className="panel-sub-title">
                                We need to know a little bit about you to
                                proceed with the application
                              </p>
                            </div>
                            <div className="mt-5">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Mobile number
                              </p>
                              <div
                                className="mb-4 ct-display-inline-block"
                                style={{ position: "relative" }}
                              >
                                <input
                                  type="number"
                                  placeholder="enter your mobile number"
                                  defaultValue={mobileNumber}
                                  className="ct-input-box"
                                  style={{ pointerEvents: "none" }}
                                />
                                <CheckCircleOutlineOutlinedIcon
                                  className="check-icon-green"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: 10,
                                    transform: "translateY(-50%)",
                                    color: "green",
                                  }}
                                />
                              </div>
                              <div className="mb-4">
                                <p
                                  className="copy-right-text mb-2"
                                  style={{ fontWeight: 600, color: "#FFF" }}
                                >
                                  Full name
                                </p>

                                <div className="">
                                  <input
                                    type="text"
                                    value={fullName}
                                    placeholder="enter your full name"
                                    className="ct-input-box"
                                    onChange={handleFullNameChange}
                                  />
                                </div>
                                <div className="pt-2">
                                  <p className="text-danger ct-text-14">
                                    {fullNameError}
                                  </p>
                                </div>
                              </div>

                              <div className="mb-4">
                                <p
                                  className="copy-right-text mb-2"
                                  style={{ fontWeight: 600, color: "#FFF" }}
                                >
                                  Email ID
                                </p>

                                <div className="">
                                  <input
                                    type="email"
                                    value={email}
                                    placeholder="enter your email ID"
                                    className="ct-input-box"
                                    onChange={handleEmailChange}
                                  />
                                </div>
                                <div className="pt-2">
                                  <p className="text-danger ct-text-14">
                                    {emailError}
                                  </p>
                                </div>
                              </div>

                              <div className="mb-4">
                                <p
                                  className="copy-right-text mb-2"
                                  style={{ fontWeight: 600, color: "#FFF" }}
                                >
                                  Gender
                                </p>

                                <div className="ct-pointer">
                                  <Select
                                    className="ct-input-box"
                                    style={{ padding: 0 }}
                                    value={selectedGender}
                                    onChange={handleGenderChange}
                                  >
                                    <MenuItem key="default" value="">
                                      Select Gender
                                    </MenuItem>
                                    <MenuItem key="Male" value="Male">
                                      Male
                                    </MenuItem>
                                    <MenuItem key="Female" value="Female">
                                      Female
                                    </MenuItem>
                                    <MenuItem key="Other" value="Other">
                                      Other
                                    </MenuItem>
                                  </Select>
                                  {/* <select
                                    value={selectedGender}
                                    onChange={handleGenderChange}
                                    className="ct-input-box p-3"
                                  >
                                    <option key="default" value="">Select Gender</option>
                                    <option key="male" value="male">Male</option>
                                    <option key="female" value="female">Female</option>
                                    <option key="other" value="other">Other</option>
                                  </select> */}
                                </div>
                                <div className="pt-2">
                                  <p className="text-danger ct-text-14">
                                    {genderError}
                                  </p>
                                </div>
                              </div>

                              <div className="mb-4">
                                <p
                                  className="copy-right-text mb-2"
                                  style={{ fontWeight: 600, color: "#FFF" }}
                                >
                                  Date of birth
                                </p>

                                <div className="ct-display-flex">
                                  <DatePicker
                                    selected={selectedDateofBirth}
                                    onChange={handleDOBChange}
                                    value={selectedDateofBirth}
                                    dateFormat="dd-MM-yyyy"
                                    style={{
                                      border: "none",
                                      pointerEvents: "none",
                                    }}
                                    className="ct-input-box ct-pointer"
                                  />
                                  {selectedDateofBirth && (
                                    <p className="mb-0">
                                      <span className="input-dob-badge dob-years-badge">
                                        {calculateAge(selectedDateofBirth)}{" "}
                                        years
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="pt-2">
                                  <p className="text-danger ct-text-14">
                                    {dobError}
                                  </p>
                                </div>
                              </div>
                              <div className="mb-4 web-display-flex">
                                <div className="ct-state-width">
                                  <p
                                    className="copy-right-text mb-2"
                                    style={{ fontWeight: 600, color: "#FFF" }}
                                  >
                                    State
                                  </p>

                                  <div className="ct-pointer">
                                    <Autocomplete
                                      className="ct-border-gray ct-pointer"
                                      style={{ color: "#FFF" }}
                                      id="combo-box-demo"
                                      options={stateList}
                                      value={selectedState}
                                      getOptionLabel={(option) =>
                                        option?.state_name
                                      }
                                      onChange={handleStateChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ color: "#FFF" }}
                                          placeholder="Select state"
                                        />
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option._id === value._id
                                      }
                                    />
                                  </div>
                                  <div className="pt-2">
                                    <p className="text-danger ct-text-14">
                                      {stateError}
                                    </p>
                                  </div>
                                </div>

                                <div className="ct-state-width ct-ml-20">
                                  <p
                                    className="copy-right-text mb-2"
                                    style={{ fontWeight: 600, color: "#FFF" }}
                                  >
                                    City
                                  </p>

                                  <div className="ct-pointer">
                                    <Autocomplete
                                      className="ct-border-gray ct-pointer"
                                      style={{ color: "#FFF" }}
                                      id="combo-box-demo"
                                      options={cityList}
                                      onChange={handleCityChange}
                                      value={selectedCity}
                                      getOptionLabel={(option) =>
                                        option.city_name
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ color: "#FFF" }}
                                          placeholder="Select city"
                                        />
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option._id === value._id
                                      }
                                    />
                                  </div>
                                  <div className="pt-2">
                                    <p className="text-danger ct-text-14">
                                      {cityError}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="">
                                <button
                                  onClick={saveUser}
                                  className="event-form-next-btn mbl-my-4"
                                >
                                  Next
                                </button>
                              </div>
                              <Toaster
                                position="top-center"
                                reverseOrder={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="event-second-section">
                        <img src={AwardImg} className="ct-full-width" />
                      </div>
                    </div>
                  )}
                </div>
              </Fade>

              {/* Tell us about your project */}
              <div className="">
                {currentTab === 2 && (
                  <div className="ct-d-flex-justify-center-web ct-flex-row mb-5 pb-4">
                    <div className="event-first-section">
                      <div className="">
                        <div className="ct-text-left">
                          <div className="ct-display-flex">
                            <div className="mt-2 ct-pointer">
                              <ArrowCircleLeftOutlinedIcon
                                onClick={navigateToUserForm}
                                style={{
                                  borderWidth: 2,
                                  borderColor: "#FFF",
                                  borderRadius: 50,
                                  width: 40,
                                  height: 40,
                                  borderStyle: "solid",
                                }}
                              />
                            </div>
                            <div className="" style={{ paddingLeft: 15 }}>
                              <h1 className="ct-cirka-size-30">
                                Tell us about your project
                              </h1>
                              <div className="mt-2 mb-3">
                                <p className="panel-sub-title">
                                  Let us make your project ready for the big
                                  screen
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="mt-5">
                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Project Category
                              </p>

                              <div className="ct-pointer">
                                <Autocomplete
                                  className="auto-complete-width ct-border-gray"
                                  style={{ color: "#FFF" }}
                                  id="combo-box-demo"
                                  options={projectCategoryList}
                                  value={selectedProjectCategory}
                                  getOptionLabel={(option) =>
                                    option?.category_name || ""
                                  }
                                  onChange={handleProjectCategoryChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ color: "#FFF" }}
                                      placeholder="Select project category"
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.category_id === value.category_id
                                  } // Customize the equality test if needed
                                />
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {projectCategoryError}
                                </p>
                              </div>
                            </div>
                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Project Title
                              </p>

                              <div className="">
                                <input
                                  type="text"
                                  value={projectTitle}
                                  placeholder="eg. Tales of Africa"
                                  className="ct-input-box"
                                  onChange={handleProjectTitleChange}
                                />
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {projectTitleError}
                                </p>
                              </div>
                            </div>

                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Project Description
                              </p>

                              <div className="">
                                <textarea
                                  maxLength={500}
                                  value={projectDescription}
                                  onChange={handleProjectDescriptionChange}
                                  placeholder="eg. Tales of Africa is a documentary series revolving around a visionary in Africa"
                                  className="ct-textarea-box"
                                />
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {projectDescriptionError}
                                </p>
                              </div>
                            </div>

                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Genre
                              </p>

                              <div className="ct-pointer">
                                <Select
                                  style={{ padding: 0 }}
                                  className="ct-input-box"
                                  value={selectedGenre}
                                  onChange={handleGenreChange}
                                >
                                  <MenuItem value="">Select Genre</MenuItem>
                                  {genreList.map((genre) => (
                                    <MenuItem
                                      key={genre?._id}
                                      value={genre?._id}
                                    >
                                      {genre?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {genreError}
                                </p>
                              </div>
                            </div>

                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Language
                              </p>

                              <div className="ct-pointer">
                                <Autocomplete
                                  className="auto-complete-width ct-border-gray"
                                  style={{ color: "#FFF" }}
                                  id="combo-box-demo"
                                  options={languageList}
                                  value={selectedLanguage}
                                  getOptionLabel={(option) =>
                                    option?.language_name || ""
                                  }
                                  onChange={handleLanguageChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ color: "#FFF" }}
                                      placeholder="Select language"
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.language_id === value.language_id
                                  } // Customize the equality test if needed
                                />
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {languageError}
                                </p>
                              </div>
                            </div>

                            <div className="mb-4">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Upload Project File
                              </p>

                              {!fileUploaderDetail.fileName && (
                                <div className="mt-3 pt-2">
                                  <p className="mb-1">
                                    <span
                                      className={`media-upload-bg ct-pointer`}
                                      onClick={handleUploadFileClick}
                                    >
                                      Upload
                                    </span>
                                  </p>
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    accept="video/*"
                                    onChange={onFileSelect}
                                  />
                                </div>
                              )}

                              {stopUploaderDetail.isEnableUpload && (
                                <div className="mt-3 pt-2">
                                  <p className="mb-1">
                                    <span
                                      className={`media-upload-bg ct-pointer`}
                                      onClick={handleUploadFileClick}
                                    >
                                      Upload
                                    </span>
                                  </p>
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    accept="video/*"
                                    onChange={onFileSelect}
                                  />
                                </div>
                              )}

                              {stopUploaderDetail.isDisableProgress && (
                                <>
                                  <div
                                    className="ct-display-flex pt-3"
                                    style={{
                                      display:
                                        fileUploaderDetail.doFileUpload &&
                                        fileUploaderDetail.fileName
                                          ? "flex"
                                          : "none",
                                    }}
                                  >
                                    <div className="ct-input-box-outline w-350px">
                                      <p
                                        className="mb-0 truncate-text"
                                        style={{ maxWidth: "30ch" }}
                                      >
                                        {fileUploaderDetail.fileName}
                                      </p>
                                    </div>
                                    {fileUploaderDetail.status !=
                                      "Uploading" && (
                                      <div
                                        className="ct-pointer"
                                        style={{
                                          paddingTop: 10,
                                          paddingLeft: 10,
                                        }}
                                        onClick={stopUploadVideo}
                                      >
                                        <span>
                                          <HighlightOffIcon className="ct-text-18" />
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      className=""
                                      style={{
                                        marginTop: 10,
                                        marginLeft: 10,
                                        display:
                                          fileUploaderDetail.status ===
                                          "Uploading"
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <p
                                        className="mb-1"
                                        onClick={stopUploadVideo}
                                      >
                                        <span className="media-stop-bg ct-pointer">
                                          Stop
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="mt-3"
                                    style={{
                                      display:
                                        fileUploaderDetail.doFileUpload &&
                                        fileUploaderDetail.fileName
                                          ? "flex"
                                          : "none",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="auto-progress-bar-width"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <LinearProgress
                                        color="secondary"
                                        value={fileUploaderDetail?.progress}
                                        variant="determinate"
                                      />
                                    </div>
                                    <div>
                                      <p className="panel-sub-title mb-0">
                                        {fileUploaderDetail?.progress === 100
                                          ? "Uploaded"
                                          : `Uploading ${fileUploaderDetail?.progress}%`}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="mb-4 pt-3">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Project Completion Date
                              </p>

                              <div className="">
                                <DatePicker
                                  selected={selectedDateCompletion}
                                  onChange={handleDateCompletionChange}
                                  value={selectedDateCompletion}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  style={{
                                    border: "none",
                                    pointerEvents: "none",
                                  }}
                                  className="ct-input-box ct-pointer"
                                />
                              </div>
                              <div className="pt-2">
                                <p className="text-danger ct-text-14">
                                  {completionDateError}
                                </p>
                              </div>
                            </div>

                            <div className="">
                              <button
                                onClick={saveProject}
                                className="event-form-next-btn"
                              >
                                Next
                              </button>
                              <Toaster
                                position="top-center"
                                reverseOrder={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="event-second-section">
                      <img src={AwardImg} className="ct-full-width" />
                    </div>
                  </div>
                )}
              </div>

              {/* Project List */}

              <div className="">
                {currentTab === 3 && (
                  <div className="head-div delete-dialog-bg">
                    <Fade direction="right" cascade>
                      <div className="">
                        <div className="">
                          <h1 className="ct-cirka-size-40 mb-2">
                            Casttree Awards 2024
                          </h1>
                          <div className="mt-4 mb-3 pt-3">
                            <p className="ct-text-20 ct-text-gray">
                              You can add new project & nominations or continue
                              application
                            </p>
                          </div>
                        </div>
                        <div
                          className="scroll-container"
                          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                        >
                          {projectLists?.map((project, plListIdx) => (
                            <div
                              className=""
                              key={"plListIdx" + plListIdx}
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                                marginRight: "10px",
                                backgroundColor: "#FFF",
                                padding: 15,
                                borderRadius: 10,
                                width: 300,
                              }}
                            >
                              <p className="ct-text-20 ct-text-weight-600 ct-text-dark mb-1">
                                {project.title}
                                {project?.documentStatus === "Draft" && (
                                  <span
                                    className=""
                                    style={{ float: "right" }}
                                    onClick={() =>
                                      openProjectDeleteDialog(project._id)
                                    }
                                  >
                                    <DeleteRoundedIcon className="ct-text-18 text-danger" />
                                  </span>
                                )}
                              </p>
                              <BootstrapDialog
                                aria-labelledby="customized-dialog-title"
                                open={showProjectDeleteDialog}
                              >
                                <p
                                  className="ct-text-18 ct-text-weight-600 ct-text-light px-3 py-2"
                                  style={{ position: "relative", top: 5 }}
                                  id="customized-dialog-title"
                                >
                                  Delete Project
                                </p>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleProjectDialogClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: "#FFF",
                                  }}
                                  className="ct-pointer"
                                >
                                  <HighlightOffIcon className="ct-text-18" />
                                </IconButton>
                                <DialogContent dividers>
                                  <div className="">
                                    <div className="">
                                      <p className="ct-text-16 ct-text-weight-500 ct-text-light">
                                        Are you sure you want to delete this
                                        project?
                                      </p>
                                    </div>
                                    <div
                                      className=""
                                      style={{ float: "right" }}
                                    >
                                      <button
                                        className="back-btn-small-outline"
                                        onClick={handleProjectDialogClose}
                                        style={{ marginRight: 15 }}
                                      >
                                        No
                                      </button>
                                      <button
                                        className="delete-btn-small"
                                        onClick={() => {
                                          if (projectIdToDelete) {
                                            removeProject(projectIdToDelete);
                                          }
                                          handleProjectDialogClose(); // Close the dialog after deleting the project
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                </DialogContent>
                              </BootstrapDialog>

                              {/* <p className="ct-text-16 ct-text-weight-600 mb-1 text-dark">
                               Project Submit Date: {projectCreatedDate}
                              </p> */}
                              <p
                                className={`ct-text-16 ct-text-weight-600 mb-4 ${
                                  project?.documentStatus === "Draft"
                                    ? "text-warning"
                                    : project?.documentStatus === "Completed"
                                    ? "text-success"
                                    : ""
                                }`}
                              >
                                {project?.documentStatus === "Completed"
                                  ? "Application submitted"
                                  : project?.documentStatus === "Draft"
                                  ? "Draft"
                                  : ""}
                              </p>
                              <p
                                className="ct-text-14 ct-text-weight-600 ct-text-dark"
                                onClick={
                                  project?.documentStatus !== "Completed"
                                    ? () => editProject(project?._id)
                                    : () => downloadApplication(project?._id)
                                }
                              >
                                <span
                                  style={{
                                    padding: 15,
                                    borderWidth:
                                      project?.documentStatus === "Completed"
                                        ? 1
                                        : 1,
                                    borderStyle: "solid",
                                    borderColor: "#222",
                                    borderRadius: 10,
                                  }}
                                >
                                  {project?.documentStatus === "Draft"
                                    ? "Continue Application"
                                    : project?.documentStatus === "Completed"
                                    ? "Download Confirmation"
                                    : ""}
                                </span>
                              </p>
                            </div>
                          ))}

                          <div
                            className=""
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                              marginRight: "10px",
                              borderColor: "#FFF",
                              padding: 15,
                              borderStyle: "solid",
                              borderRadius: 10,
                              borderWidth: 1,
                              width: 120,
                              height: 150,
                              position: "relative",
                              bottom:
                                projectLists && projectLists?.length > 0
                                  ? 73
                                  : 0,
                            }}
                            onClick={navigateToProjectCreate}
                          >
                            <p
                              className="ct-text-20 ct-text-weight-600 ct-text-light mb-1"
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                                position: "relative",
                                top: 30,
                                alignSelf: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              Add new <br />
                              project
                            </p>
                          </div>
                        </div>
                        <div
                          className="ct-display-flex-bottom"
                          style={{
                            borderTopWidth: 1,
                            borderTopColor: "#414141",
                            borderTopStyle: "solid",
                            paddingTop: 30,
                          }}
                        >
                          <div className="ct-bottom-width-50">
                            <p
                              className="ct-text-20 ct-text-gray"
                              style={{ fontWeight: 600 }}
                            >
                              Download{" "}
                              <span className="ct-text-light">
                                {" "}
                                Casttree App{" "}
                              </span>{" "}
                              to keep yourselves updated on your nominations and
                              events. You can:
                            </p>
                            <ul style={{ paddingLeft: 10 }}>
                              <li key="1" className="mb-2">
                                <FiberManualRecordIcon
                                  style={{
                                    paddingRight: 10,
                                  }}
                                />
                                Check application status
                              </li>
                              <li key="2" className="mb-2">
                                <FiberManualRecordIcon
                                  style={{
                                    paddingRight: 10,
                                  }}
                                />
                                Access to feedbacks from Casttree experts
                              </li>
                              <li key="3" className="mb-2">
                                <FiberManualRecordIcon
                                  style={{
                                    paddingRight: 10,
                                  }}
                                />
                                Real-time updates on the event
                              </li>
                            </ul>
                          </div>
                          <div className="ct-bottom-width-40">
                            <div className="">
                              <div className="">
                                <p
                                  className="ct-text-20 ct-text-gray"
                                  style={{ fontWeight: 600 }}
                                >
                                  Download the{" "}
                                  <span className="ct-text-light">
                                    {" "}
                                    Casttree App{" "}
                                  </span>{" "}
                                  from the <br /> Play Store or the App Store
                                </p>
                                <div className="ct-display-flex">
                                  <div className="">
                                    <a
                                      href="https://fv4b.short.gy/PKKWyx"
                                      target="_blank"
                                    >
                                      <img
                                        src={playstore}
                                        width={150}
                                        style={{ marginRight: 20 }}
                                      />
                                    </a>
                                  </div>
                                  <div className="">
                                    <a
                                      href="https://fv4b.short.gy/YCG6ws"
                                      target="_blank"
                                    >
                                      <img
                                        src={appstore}
                                        width={150}
                                        style={{ marginTop: 10 }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ct-bottom-width-10">
                            <div className="text-center qr-code-position">
                              <img src={QRCode} width={100} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </div>
                )}
              </div>

              {/* Choose your nominations */}
              <div className="">
                {currentTab === 4 && (
                  <div className="ct-d-flex-justify-center-web ct-flex-row mb-5 pb-4">
                    <div className="event-first-section">
                      <div className="">
                        <div className="ct-text-left">
                          <div className="ct-display-flex">
                            <div className="mt-2 ct-pointer">
                              <ArrowCircleLeftOutlinedIcon
                                onClick={navigateToProjectForm}
                                style={{
                                  borderWidth: 2,
                                  borderColor: "#FFF",
                                  borderRadius: 50,
                                  width: 40,
                                  height: 40,
                                  borderStyle: "solid",
                                }}
                              />
                            </div>
                            <div className="" style={{ paddingLeft: 15 }}>
                              <h1 className="ct-cirka-size-30">
                                Choose your nominations
                              </h1>
                              <div className="mt-2 mb-3">
                                <p className="panel-sub-title">
                                  Select all your nominations for your work
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="nominee-left-5 mt-4">
                            <div className="mb-4">
                              {groupAwardList?.length > 0 && (
                                <div className="mb-4">
                                  <p className="ct-text-18 ct-text-gray ct-gilroy-semibold mb-1">
                                    Group Awards
                                  </p>
                                  <p className="panel-sub-title">
                                    You can select more than one category for
                                    your project nomination
                                  </p>
                                </div>
                              )}
                              <div className="">
                                {groupAwardList?.map((award) => (
                                  <div
                                    className={`ct-flex-row ct-display-flex ct-p-12 mb-3 ct-pointer w-500px ct-space-between ${
                                      award.checked
                                        ? "gradient-border-1"
                                        : "ct-border-gray"
                                    }`}
                                  >
                                    <div
                                      className=""
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      {award?.media?.map((media, index) => (
                                        <img
                                          key={index}
                                          src={media?.media_id?.media_url}
                                          className="nomine-img"
                                          alt={`Award Media ${index}`}
                                        />
                                      ))}
                                    </div>
                                    <div className="award-right-padding">
                                      <p
                                        className="copy-right-text mb-1"
                                        style={{
                                          color: "#FFF",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {award?.title}
                                      </p>
                                      <p
                                        className="panel-sub-title mb-1"
                                        dangerouslySetInnerHTML={{
                                          __html: award?.description,
                                        }}
                                      ></p>
                                    </div>
                                    <div
                                      className="check-box-mr-0"
                                      style={{
                                        paddingLeft: 15,
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <div className="ct-display-flex">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={award.checked} // Check if the checkbox is checked based on state
                                              onChange={() =>
                                                handleGroupAwardItemClick(award)
                                              } // Call the function with the award title as value
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 28,
                                                  color: "#ee35ff",
                                                },
                                              }}
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="">
                              {individualAwardList?.length > 0 && (
                                <div className="mb-4">
                                  <p className="ct-text-18 ct-text-gray ct-gilroy-semibold mb-1">
                                    Individual Awards
                                  </p>
                                  <p className="panel-sub-title">
                                    You can nominate multiple people in your
                                    project to each category
                                  </p>
                                </div>
                              )}
                              <div className="">
                                <div className="">
                                  {individualAwardList?.map((award) => (
                                    <div
                                      className={`ct-flex-row ct-display-flex ct-pointer ct-p-12 mb-3 w-500px ct-border-radius-10 ct-space-between ${
                                        award.count > 0
                                          ? "gradient-border-1"
                                          : "ct-border-gray"
                                      }`}
                                    >
                                      <div
                                        className=""
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        {award?.media?.map((media, index) => (
                                          <img
                                            key={index}
                                            src={media?.media_id?.media_url}
                                            className="nomine-img"
                                            alt={`Award Media ${index}`}
                                          />
                                        ))}
                                      </div>
                                      <div className="award-right-padding">
                                        <p
                                          className="copy-right-text mb-1"
                                          style={{
                                            color: "#FFF",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {award?.title}
                                        </p>
                                        <p
                                          className="panel-sub-title mb-1"
                                          dangerouslySetInnerHTML={{
                                            __html: award?.description,
                                          }}
                                        ></p>
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          paddingLeft: 15,
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                          }}
                                        >
                                          <p
                                            className="mb-0"
                                            onClick={() =>
                                              decIndAwardCount(award)
                                            }
                                          >
                                            <span className="plus-minus-bg">
                                              -
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          className=""
                                          style={{
                                            width: "35px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <p
                                            className="copy-right-text mb-1"
                                            style={{
                                              color: "#FFF",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {award.count}
                                          </p>
                                        </div>
                                        <div
                                          className=""
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <p
                                            className="mb-0"
                                            onClick={() =>
                                              incIndAwardCount(award)
                                            }
                                          >
                                            <span className="plus-minus-bg">
                                              +
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="event-second-section-total nominee-left-5 ">
                      <div className="ct-text-left ct-bg-gray p-3 ct-border-radius-10">
                        <h1 className="ct-cirka-size-30 ct-background-transparent">
                          Your nominations
                        </h1>
                        <div className="mt-2 mb-3 ct-background-transparent">
                          <p className="panel-sub-title ct-background-transparent">
                            Summary of your event registration fees for your
                            nominations
                          </p>
                        </div>
                        <div
                          className="my-3"
                          style={{
                            display:
                              fileUploaderDetail.doFileUpload &&
                              fileUploaderDetail.fileName
                                ? "flex"
                                : "none",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="auto-progress-bar-width-70"
                            style={{ marginRight: "10px" }}
                          >
                            <LinearProgress
                              color="secondary"
                              value={fileUploaderDetail?.progress}
                              variant="determinate"
                            />
                          </div>
                          <div>
                            <p className="panel-sub-title mb-0">
                              {fileUploaderDetail?.progress === 100
                                ? "Uploaded"
                                : `Uploading ${fileUploaderDetail?.progress}%`}
                            </p>
                          </div>
                        </div>
                        <div className="ct-border-gray p-3 ct-background-transparent">
                          {groupAwardList.filter((e) => e.checked).length > 0 ||
                          individualAwardList.filter((e) => e.count).length >
                            0 ? (
                            <>
                              <div>
                                {groupAwardList
                                  ?.filter((e) => e.checked)
                                  .map((award, index) => (
                                    <p
                                      key={index}
                                      className="ct-display-flex ct-space-between ct-font-16 ct-text-weight-600 ct-background-transparent py-1"
                                    >
                                      <span className="ct-background-transparent">
                                        {award?.title}
                                      </span>
                                      <span className="ct-background-transparent">
                                        {award?.currency} {award?.price}{" "}
                                      </span>
                                    </p>
                                  ))}

                                {individualAwardList
                                  .filter((e) => e.count)
                                  .map((awardId, index) => (
                                    <p
                                      key={index}
                                      className="ct-display-flex ct-space-between ct-font-16 ct-text-weight-600 ct-background-transparent py-1"
                                    >
                                      <span className="ct-background-transparent">
                                        {awardId.title} x {awardId.count}
                                      </span>
                                      <span className="ct-background-transparent">
                                        {awardId.currency}{" "}
                                        {awardId.count * awardId.price}
                                      </span>
                                    </p>
                                  ))}

                                {!selectedCoupon && (
                                  <div className="ct-border-gray p-3 my-3">
                                    <p className="ct-text-15 ct-text-weight-600 ct-text-gray mb-0">
                                      Coupon Code
                                      <span
                                        className="gradient-text ct-float-right ct-pointer"
                                        onClick={handleApplyCoupon}
                                      >
                                        Select Coupon
                                      </span>
                                    </p>
                                  </div>
                                )}

                                {selectedCoupon && (
                                  <div className="ct-border-gray p-3 my-3">
                                    <p className="ct-text-15 ct-text-weight-600 ct-text-light mb-1">
                                      {selectedCoupon.name} applied
                                      <span
                                        className="gradient-text ct-float-right ct-pointer"
                                        onClick={removeCoupon}
                                      >
                                        Remove
                                      </span>
                                    </p>
                                    <p className="panel-sub-title ct-text-gray mb-0">
                                      You have got ₹
                                      {selectedCoupon.discountValue} discount
                                    </p>
                                  </div>
                                )}

                                <BootstrapDialog
                                  onClose={handleCloseCoupon}
                                  aria-labelledby="customized-dialog-title"
                                  open={showCouponPopUp}
                                >
                                  <div className="p-3">
                                    <p
                                      className="ct-text-18 ct-text-weight-600 ct-text-light mb-1"
                                      id="customized-dialog-title"
                                    >
                                      Coupons
                                    </p>
                                    <p className="panel-sub-title ct-text-gray">
                                      Choose your preferred coupon
                                    </p>
                                  </div>
                                  <IconButton
                                    aria-label="close"
                                    onClick={handleCloseCoupon}
                                    sx={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      color: "#FFF",
                                      cursor: "pointer",
                                    }}
                                    className="ct-pointer"
                                  >
                                    <HighlightOffIcon className="ct-text-18" />
                                  </IconButton>
                                  <div className="ct-width-400">
                                    <DialogContent dividers>
                                      <div>
                                        {awardCouponList.length === 0 ? (
                                          <div className="">
                                            <Box sx={{ width: 300 }}>
                                              <Skeleton />
                                              <Skeleton animation="wave" />
                                              <Skeleton animation={false} />
                                            </Box>
                                          </div>
                                        ) : (
                                          <div>
                                            {awardCouponList.map(
                                              (coupon, index) => (
                                                <div
                                                  className={`p-3 ct-border-radius-10 mb-3 ${
                                                    coupon?.isEnabled
                                                      ? "ct-coupon-gradient-border"
                                                      : "ct-border-gray "
                                                  }`}
                                                  key={index}
                                                >
                                                  <div className="ct-border-top-dashed">
                                                    <p className="ct-text-24 mb-2 ct-text-weight-600 ct-text-light">
                                                      {coupon?.name}
                                                      <span className="ct-float-right">
                                                        ₹{" "}
                                                        {coupon?.discountValue}{" "}
                                                        Off
                                                      </span>
                                                    </p>
                                                    <p className="panel-sub-title ct-text-gray">
                                                      Apply this coupon and save
                                                      ₹ {coupon?.discountValue}{" "}
                                                      now
                                                    </p>
                                                  </div>
                                                  <div className="mt-3">
                                                    <p className="ct-text-13 ct-text-gray">
                                                      {
                                                        coupon?.termsAndCondition
                                                      }
                                                    </p>
                                                    <div className="ct-display-flex justify-content-end">
                                                      <button
                                                        onClick={() =>
                                                          couponApply(coupon)
                                                        }
                                                        style={{
                                                          background: "#FFF",
                                                          color: "#000",
                                                        }}
                                                        disabled={
                                                          !coupon?.isEnabled
                                                        }
                                                        className={`${
                                                          coupon?.isEnabled
                                                            ? "apply-coupon-btn-smal-gradient"
                                                            : "apply-coupon-btn-small "
                                                        }`}
                                                      >
                                                        Apply Coupon
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </DialogContent>
                                  </div>
                                </BootstrapDialog>

                                <div className="ct-text-center">
                                  <p className="ct-text-gray ct-text-13 ct-text-weight-500">
                                    Nominate atleast 3 nominations and get upto
                                    ₹ 249 off
                                  </p>
                                </div>

                                {selectedCoupon && (
                                  <p className="ct-display-flex ct-text-green pt-2 ct-space-between ct-text-16 ct-text-weight-600 mb-1 py-1 ct-background-transparent py-1">
                                    <span className="ct-background-transparent">
                                      Discount{" "}
                                    </span>
                                    <span className="ct-background-transparent">
                                      - INR {selectedCoupon.discountValue}
                                    </span>
                                  </p>
                                )}

                                <p className="ct-display-flex ct-space-between ct-text-26 ct-text-weight-600 mb-1 pt-3 ct-background-transparent py-1">
                                  <span className="ct-background-transparent">
                                    Grand Total{" "}
                                  </span>
                                  <span className="ct-background-transparent">
                                    INR {calculateGrandTotal().toLocaleString()}
                                  </span>
                                </p>

                                <div className="ct-background-transparent pt-3 m-2">
                                  {individualAwardList.some(
                                    (award) => award.count > 0
                                  ) && (
                                    <button
                                      onClick={saveApplication}
                                      style={{
                                        background: "#FFFFFF",
                                        color: "#000",
                                        border: "none",
                                        borderRadius: "5px",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        width: "100%",
                                        padding: "15px",
                                        fontFamily: "gilroy-bold",
                                      }}
                                    >
                                      Next
                                    </button>
                                  )}

                                  {/* Render the "Proceed to Pay" button */}
                                  {!individualAwardList.some(
                                    (award) => award?.count > 0
                                  ) && (
                                    <button
                                      id="rzp-button1"
                                      onClick={withoutIndividualAward}
                                      disabled={
                                        loadingProceedToPay ||
                                        fileUploaderDetail.status ===
                                          "uploading"
                                      }
                                      style={{
                                        background:
                                          "linear-gradient(90deg, #EE36FF 0%, #FF8762 100%)",
                                        color: "#FFF",
                                        border: "none",
                                        borderRadius: "5px",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        width: "100%",
                                        padding: "15px",
                                        fontFamily: "gilroy-bold",
                                      }}
                                    >
                                      Proceed to pay
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="ct-background-transparent">
                              <p className="ct-text-16 ct-text-gray text-center ct-text-weight-600 mb-1 pt-3 ct-background-transparent">
                                Select a nomination category to show amount
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Add your nominee & crew details */}
              <div className="">
                {currentTab === 5 && (
                  <div className="ct-d-flex-justify-center-web ct-flex-row">
                    <div className="event-first-section">
                      <div className="">
                        <div className="ct-text-left">
                          <div className="ct-display-flex">
                            <div className="mt-2 ct-pointer">
                              <ArrowCircleLeftOutlinedIcon
                                onClick={navigateToAwardChoose}
                                style={{
                                  borderWidth: 2,
                                  borderColor: "#FFF",
                                  borderRadius: 50,
                                  width: 40,
                                  height: 40,
                                  borderStyle: "solid",
                                }}
                              />
                            </div>
                            <div className="" style={{ paddingLeft: 15 }}>
                              <h1 className="ct-cirka-size-30">
                                Add your nominee & crew details
                              </h1>
                              <div className="mt-2 mb-3">
                                <p className="panel-sub-title">
                                  Tell us more details about your nominee for
                                  each award category & project crew
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            {repeatedIndividualAwards.map((nominee, index) => (
                              <div key={index}>
                                {index === nomineeFormCurrentIdx && (
                                  <div>
                                    <div className="nominee-left-5 mt-4 ct-border-gray w-500px p-4">
                                      <div className="">
                                        <h1 className="ct-cirka-size-30 mb-3">
                                          Nomination {index + 1}:{" "}
                                          {nominee?.awardId?.title}
                                        </h1>
                                        <div className="mt-2 mb-3">
                                          <p className="panel-sub-title">
                                            Tell us about your nominee for the{" "}
                                            {nominee?.awardId?.title} category
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="mt-2">
                                            <FormGroup
                                              className=""
                                              onChange={(e) =>
                                                handleNomineeDetailPrefill(
                                                  index,
                                                  e
                                                )
                                              }
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={checkboxChecked}
                                                    sx={{
                                                      "& .MuiSvgIcon-root": {
                                                        fontSize: 28,
                                                        color: "#ee35ff",
                                                      },
                                                    }}
                                                  />
                                                }
                                              />
                                              <label
                                                style={{
                                                  color: "#FFF",
                                                  paddingTop: 10,
                                                  position: "relative",
                                                  fontFamily: "Gilroy-Medium",
                                                  right: 15,
                                                }}
                                              >
                                                Add myself as the nominee
                                              </label>
                                            </FormGroup>
                                          </div>
                                          <div className="mt-3">
                                            <div className="mb-4">
                                              <p
                                                className="copy-right-text mb-2"
                                                style={{
                                                  fontWeight: 600,
                                                  color: "#FFF",
                                                }}
                                              >
                                                Full name
                                              </p>

                                              <div className="">
                                                <input
                                                  value={nomineeFullName}
                                                  type="text"
                                                  placeholder="enter your full name"
                                                  className={`ct-input-box ${
                                                    checkboxChecked
                                                      ? "disabled-input"
                                                      : ""
                                                  }`}
                                                  onChange={
                                                    handleNomineeFullNameChange
                                                  }
                                                  readOnly={checkboxChecked}
                                                />
                                              </div>
                                              <div className="pt-2">
                                                <p className="text-danger ct-text-14">
                                                  {nomineeFullNameError}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mb-4">
                                              <p
                                                className="copy-right-text mb-2"
                                                style={{
                                                  fontWeight: 600,
                                                  color: "#FFF",
                                                }}
                                              >
                                                Mobile Number
                                              </p>

                                              <div className="">
                                                <input
                                                  value={nomineeMobileNumber}
                                                  type="number"
                                                  placeholder="enter your mobile number"
                                                  className={`ct-input-box ${
                                                    checkboxChecked
                                                      ? "disabled-input"
                                                      : ""
                                                  }`}
                                                  onChange={
                                                    handleNomineeMobileNumberChange
                                                  }
                                                  readOnly={checkboxChecked}
                                                />
                                              </div>
                                              <div className="pt-2">
                                                {isValidNumber ? null : (
                                                  <p className="text-danger ct-text-14">
                                                    {nomineeMobileNumberError}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <div className="mb-4">
                                              <p
                                                className="copy-right-text mb-2"
                                                style={{
                                                  fontWeight: 600,
                                                  color: "#FFF",
                                                }}
                                              >
                                                Email ID (optional)
                                              </p>

                                              <div className="">
                                                <input
                                                  type="email"
                                                  value={nomineeEmailID}
                                                  placeholder="enter your mail ID"
                                                  className="ct-input-box"
                                                  onChange={
                                                    handleNomineeEmailIdChange
                                                  }
                                                  readOnly={
                                                    checkboxChecked &&
                                                    nomineeEmailID
                                                  }
                                                />
                                              </div>
                                              <div className="pt-2">
                                                <p className="text-danger ct-text-14">
                                                  {nomineeEmailIdError}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="">
                                              <p
                                                className="copy-right-text mb-2"
                                                style={{
                                                  fontWeight: 600,
                                                  color: "#FFF",
                                                }}
                                              >
                                                Character name/ Role description
                                              </p>

                                              <div className="">
                                                <textarea
                                                  maxLength={500}
                                                  value={nomineeDesc}
                                                  placeholder="enter details to identify the nominee in the project like a character name or role in the project. 
                                                                    eg. David character in the movie, Male dancer in red dress"
                                                  className="ct-textarea-box"
                                                  onChange={
                                                    handleNomineeDescChange
                                                  }
                                                />
                                              </div>
                                              <div className="pt-2">
                                                <p className="text-danger ct-text-14">
                                                  {nomineeDescError}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="w-500px nominee-left-5 mb-5">
                                      <div
                                        className="pt-3 m-2 ct-display-flex"
                                        style={{
                                          justifyContent:
                                            nomineeCurrentIndex === 0
                                              ? "flex-end"
                                              : "space-between",
                                        }}
                                      >
                                        {nomineeCurrentIndex > 0 && (
                                          <div style={{ marginRight: 20 }}>
                                            <button
                                              className="next-btn-small-outline"
                                              onClick={handleNomineeBack}
                                            >
                                              Back
                                            </button>
                                          </div>
                                        )}
                                        <div className="">
                                          <button
                                            className="next-btn-small"
                                            onClick={addNomination}
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="event-second-section-total nominee-left-5 ">
                      <div className="ct-text-left ct-bg-gray p-3 ct-border-radius-10">
                        <h1 className="ct-cirka-size-30 ct-background-transparent">
                          Your nominations
                        </h1>
                        <div className="mt-2 mb-3 ct-background-transparent">
                          <p className="panel-sub-title ct-background-transparent">
                            Summary of your event registration fees for your
                            nominations
                          </p>
                        </div>
                        <div
                          className="my-4"
                          style={{
                            display:
                              fileUploaderDetail.doFileUpload &&
                              fileUploaderDetail.fileName
                                ? "flex"
                                : "none",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="auto-progress-bar-width-70"
                            style={{ marginRight: "10px" }}
                          >
                            <LinearProgress
                              color="secondary"
                              value={fileUploaderDetail?.progress}
                              variant="determinate"
                            />
                          </div>
                          <div>
                            <p className="panel-sub-title mb-0">
                              {fileUploaderDetail?.progress === 100
                                ? "Uploaded"
                                : `Uploading ${fileUploaderDetail?.progress}%`}
                            </p>
                          </div>
                        </div>
                        <div className="ct-border-gray p-3 ct-background-transparent">
                          <div>
                            {groupAwardList
                              .filter((e) => e.checked)
                              .map((award, index) => (
                                <p
                                  key={index}
                                  className="ct-display-flex ct-space-between ct-font-16 ct-text-weight-600 ct-background-transparent py-1"
                                >
                                  <span className="ct-background-transparent">
                                    {award.title}
                                  </span>
                                  <span className="ct-background-transparent">
                                    {award.currency} {award.price}{" "}
                                  </span>
                                </p>
                              ))}

                            {individualAwardList
                              .filter((e) => e.count)
                              .map((awardId, index) => (
                                <p
                                  key={index}
                                  className="ct-display-flex ct-space-between ct-font-16 ct-text-weight-600 ct-background-transparent py-1"
                                >
                                  <span className="ct-background-transparent">
                                    {awardId.title} x {awardId.count}
                                  </span>
                                  <span className="ct-background-transparent">
                                    {awardId.currency}{" "}
                                    {awardId.count * awardId.price}
                                  </span>
                                </p>
                              ))}

                            <p className="ct-display-flex ct-space-between ct-text-18 ct-text-weight-600 mb-1 py-1 ct-background-transparent py-1">
                              <span className="ct-background-transparent">
                                Total{" "}
                              </span>
                              <span className="ct-background-transparent">
                                INR {calculateTotal().toLocaleString()}
                              </span>
                            </p>
                            {selectedCoupon && (
                              <p className="ct-display-flex ct-text-green pt-2 ct-space-between ct-text-16 ct-text-weight-600 mb-1 py-1 ct-background-transparent py-1">
                                <span className="ct-background-transparent">
                                  Discount{" "}
                                </span>
                                <span className="ct-background-transparent">
                                  - INR {selectedCoupon.discountValue}
                                </span>
                              </p>
                            )}

                            <p className="ct-display-flex ct-space-between ct-text-26 ct-text-weight-600 mb-1 pt-3 ct-background-transparent py-1">
                              <span className="ct-background-transparent">
                                Grand Total{" "}
                              </span>
                              <span className="ct-background-transparent">
                                INR {calculateGrandTotal().toLocaleString()}
                              </span>
                            </p>
                          </div>

                          <div className="ct-background-transparent pt-3 m-2">
                            {nomineeFormCurrentIdx <
                              repeatedIndividualAwards.length - 1 && (
                              <div
                                className="pt-3 m-2 ct-display-flex"
                                style={{
                                  justifyContent:
                                    nomineeFormCurrentIdx === 0
                                      ? "flex-end"
                                      : "space-between",
                                }}
                              >
                                {nomineeFormCurrentIdx > 0 && (
                                  <div style={{ marginRight: 20, width: "40" }}>
                                    <button
                                      className="next-btn-small-outline"
                                      onClick={handleNomineeBack}
                                    >
                                      Back
                                    </button>
                                  </div>
                                )}
                                <div className="" style={{ width: "100%" }}>
                                  <button
                                    style={{ width: "100%" }}
                                    className="next-btn-small"
                                    onClick={() => saveNomineeData(false)}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            )}
                            {!(
                              nomineeFormCurrentIdx <
                              repeatedIndividualAwards.length - 1
                            ) && (
                              <div
                                className="pt-3 m-2 ct-display-flex"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                {nomineeFormCurrentIdx > 0 && (
                                  <div style={{ marginRight: 20, width: "40" }}>
                                    <button
                                      className="next-btn-small-outline"
                                      onClick={handleNomineeBack}
                                    >
                                      Back
                                    </button>
                                  </div>
                                )}
                                <button
                                  id="rzp-button1"
                                  onClick={() => saveNomineeData(true)}
                                  disabled={
                                    loadingProceedToPay ||
                                    fileUploaderDetail.status === "uploading"
                                  }
                                  style={{
                                    background:
                                      "linear-gradient(90deg, #EE36FF 0%, #FF8762 100%)",
                                    color: "#FFF",
                                    border: "none",
                                    borderRadius: "5px",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    width: "100%",
                                    padding: "15px",
                                    fontFamily: "gilroy-bold",
                                  }}
                                >
                                  Proceed to pay
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* We Use Feature */}

      {/* <div className="">
        <div className="nominee-left-5 mt-4 ct-border-gray w-500px p-4">
          <div className="">
            <h1 className="ct-cirka-size-30">Nomination 1: Best Director</h1>
            <div className="mt-2 mb-3">
              <p className="panel-sub-title">
                Tell us about your nominee for the best director category
              </p>
            </div>
            <div className="my-3 ct-border-gray p-3">
              <p className="ct-cirka-size-25" onClick={toggleDetails}>
                <span>Aswin Dhananjai</span>
                <span>
                  {" "}
                  <KeyboardArrowDownOutlinedIcon
                    color="#FFF"
                    style={{ color: "#FFF", float: "right", cursor: "pointer" }}
                  />
                </span>
              </p>
              <p className="panel-sub-title">8737473848</p>
              <p className="panel-sub-title">Screenplay Writer</p>
              {showDetails && (
                <>
                  <p className="panel-sub-title">sureshkumar@gmail.com</p>
                  <p className="panel-sub-title">
                    These include plot development, characters, dialogue,
                    setting, and structure. A story's plot is simply the action
                    that occurs. People are referred to as characters in
                    scripts, and setting refers to the time and place in which
                    the action occurs.
                  </p>
                </>
              )}
            </div>
            <div className="">
              <div className="mb-4">
                <p
                  className="copy-right-text mb-2"
                  style={{ fontWeight: 600, color: "#FFF" }}
                >
                  Role
                </p>

                <div className="ct-display-flex ct-space-between ct-input-box-outline w-450px">
                  <p className="mb-0">select role</p>
                  <p className="mb-0">
                    <KeyboardArrowDownOutlinedIcon
                      color="#484848"
                      style={{ color: "#484848" }}
                    />
                  </p>
                </div>
              </div>

              <div className="mt-3 ct-display-flex">
                <input
                  id="custom-checkbox"
                  type="checkbox"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                <label style={{ paddingLeft: 10 }}>
                  Add myself as the nominee
                </label>
              </div>
              <div className="mt-3">
                <div className="mb-4">
                  <p
                    className="copy-right-text mb-2"
                    style={{ fontWeight: 600, color: "#FFF" }}
                  >
                    Full name
                  </p>

                  <div className="">
                    <input
                      type="text"
                      placeholder="enter your full name"
                      className="ct-input-box"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <p
                    className="copy-right-text mb-2"
                    style={{ fontWeight: 600, color: "#FFF" }}
                  >
                    Mobile Number
                  </p>

                  <div className="">
                    <input
                      type="text"
                      placeholder="enter your mobile number"
                      className="ct-input-box"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <p
                    className="copy-right-text mb-2"
                    style={{ fontWeight: 600, color: "#FFF" }}
                  >
                    Email ID (optional)
                  </p>

                  <div className="">
                    <input
                      type="text"
                      placeholder="enter your mail ID"
                      className="ct-input-box"
                    />
                  </div>
                </div>
                <div className="">
                  <p
                    className="copy-right-text mb-2"
                    style={{ fontWeight: 600, color: "#FFF" }}
                  >
                    Character name/ Role description
                  </p>

                  <div className="">
                    <textarea
                      placeholder="enter details to identify the nominee in the project like a character name or role in the project. 
                                                                    eg. David character in the movie, Male dancer in red dress"
                      className="ct-textarea-box"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nominee-left-5 mb-5">
          <div className="pt-3 ">
            <button className="next-btn-small-outline" onClick={nextTab}>
              + Add another
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CreateEvent;
