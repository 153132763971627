import React from "react";
import { Fade } from "react-awesome-reveal";
import whatElseImg from "../assets/what_else_img.png";


const WhatElse = () => {
  const windowWidth = window.innerWidth;
  let imageSize = { width: 300, height: 400 }; // Default image size
  if (windowWidth >= 1024) {
    imageSize = { width: 300, height: 400, marginRight: 15 }; // Adjust image size for large screens
  } else if (windowWidth >= 768 && windowWidth < 1024) {
    imageSize = { width: 250, height: 350, marginRight: 15 }; // Adjust image size for tablet-sized screens
  } else if (windowWidth >= 320 && windowWidth < 450) {
    imageSize = { width: 250, height: 300 }; // Adjust image size for tablet-sized screens
  }
  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="Events"
    >
      <div className="ct-top-30">
        <div className="head">
          <div className="ct-d-flex-justify-center ct-flex-row">
            <div className="about-first-section">
              <div className="mbl-text-left">
                <h1 className="ct-title-social">WHAT ELSE? A LOT MORE!</h1>
                <p className="ct-para-text">
                  The event is filled not only with fun, fame and talent - but a
                  lot more
                </p>
                <div className="mt-4">
                  <p className="ct-about-para-text">
                    Here are 5 experiences you can expect at Casttree events and awards'24.
                  </p>
                  <div className="mt-3">
                    <p className="ct-border-gray p-3 mb-3">
                      <span
                        className="else-number-bg mr-2"
                        style={{ marginRight: 10 }}
                      >
                        1
                      </span>
                      <span className="pl-2">
                        Get your work recognized by the industry experts
                      </span>
                    </p>
                    <p className="ct-border-gray p-3 mb-3">
                      <span
                        className="else-number-bg mr-2"
                        style={{ marginRight: 10 }}
                      >
                        2
                      </span>
                      <span className="pl-2">
                        Access to expert’s feedback to help you improve your
                        skills
                      </span>
                    </p>
                    <p className="ct-border-gray p-3 mb-3">
                      <span
                        className="else-number-bg mr-2"
                        style={{ marginRight: 10 }}
                      >
                        3
                      </span>
                      <span className="pl-2">
                        Network with talents from across the country
                      </span>
                    </p>
                    <p className="ct-border-gray p-3 mb-3">
                      <span
                        className="else-number-bg mr-2"
                        style={{ marginRight: 10 }}
                      >
                        4
                      </span>
                      <span className="pl-2">
                        Get your movie screened in the big screen
                      </span>
                    </p>
                    <p className="ct-border-gray p-3 mb-3">
                      <span
                        className="else-number-bg mr-2"
                        style={{ marginRight: 10 }}
                      >
                        5
                      </span>
                      <span className="pl-2">
                        Learn and improve you craft through workshops
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-second-section home-text-center" style={{marginTop: 40}}>
            <Fade direction='top-right' triggerOnce={true}>
              <img src={whatElseImg} className="else-image" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatElse;
