import React from 'react';
import { Fade } from "react-awesome-reveal";
import aboutUs from "../assets/past_stats.png"

const AboutUs = () => {
    return (
        <div
            style={{ background: "#000", color: "white" }}
            className="container mt-5 landing-page-main"
            id="AboutUs"
        >
            <div className='ct-top-30'>
                <div className="head">
                    <div className="head-div ">
                        <Fade direction='bottom-right' triggerOnce={true} cascade>
                            <div className='ct-d-flex-justify-center ct-flex-row'>
                                <div className='about-first-section'>
                                    <div className='mbl-text-left'>
                                        <h1 className="ct-title-social">About Casttree Events</h1>
                                        <div className='mt-4'>
                                            <p className="ct-about-para-text">
                                                Casttree Events & Awards, an endeavor led by Casttree, is renowned for its accomplishments and enduring commitment to the community.
                                            </p>
                                            <br/>
                                            <p className="ct-about-para-text">
                                            Casttree serves as a reputable platform for actors, performers, casting professionals, and talent scouts, providing career prospects and mentorship. The core objective of Casttree Awards is to foster emerging talent in the entertainment sector while celebrating excellence on a global scale. We take pride in acknowledging the dedication of directors, writers, actors, cinematographers, musicians, editors, dancers, and technicians.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='about-second-section home-text-center'>
                                    <img src={aboutUs} className='about-image' />
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
