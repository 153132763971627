import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import mime from "mime";
import { APP_CONSTANTS, ENV } from "../const/app.const";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

export const PEERTUBE_BASE_URL = ENV_VARIABLES.PEERTUBE_BASE_URL;
export const CASTTREE_BASE_URL = ENV_VARIABLES.CASTTREE_SERVICE_BASE_URL;
const minChunkSize = 50000000; // 50mb
const maxLastChunkReceivedInterval = 3300000;
// const maxChunkSize = 50000000;

export const EPeertubeGrantType = {
  password: "password",
  refresh_token: "refresh_token",
};

createAuthRefreshInterceptor(
  axios,
  async (failedRequest) => {
    let user = JSON.parse(localStorage.getItem("user_detail"));
    let token = localStorage.getItem("access_token");
    let peertubeToken = JSON.parse(localStorage.getItem("peertubeToken"));
    let grandType;
    if (peertubeToken?.refresh_token_expires_in > Date.now()) {
      grandType = EPeertubeGrantType.refresh_token;
    } else {
      grandType = EPeertubeGrantType.password;
    }
    let loginRes = await VideoUploaderHelper.getPeertubeToken(
      user.phoneNumber,
      token,
      grandType,
      peertubeToken?.refresh_token
    );
    const peertubeTokenLs = {
      access_token: loginRes.access_token,
      expires_in: Date.now() + loginRes.expires_in * 1000,
      refresh_token: loginRes.refresh_token,
      refresh_token_expires_in:
        Date.now() + loginRes.refresh_token_expires_in * 1000,
    };
    localStorage.setItem("peertubeToken", JSON.stringify(peertubeTokenLs));
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + loginRes.access_token;
  },
  {
    statusCodes: [401],
    shouldRefresh: (error) => {
      console.log(
        "should refresh",
        error.config.url.includes(PEERTUBE_BASE_URL)
      );
      return (
        error.config.url.includes(PEERTUBE_BASE_URL) &&
        error.response.status === 401
      );
      // return false;
    },
  }
);

async function invokeAPICall(
  requestURL,
  requestMethod,
  requestData,
  requestHeaders,
  uploadCB
) {
  try {
    const requestInput = {
      method: requestMethod,
      url: requestURL,
      headers: requestHeaders,
      data: requestData,
    };
    if (uploadCB) {
      requestInput["onUploadProgress"] = uploadCB;
    }
    let data = await new Promise((res, rej) => {
      axios
        .request(requestInput)
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
    return data;
  } catch (err) {
    throw err;
  }
}

const VideoUploaderHelper = {
  getPeertubeToken: async (
    phoneNumber,
    accessToken,
    grantType,
    refresh_token
  ) => {
    const baseURL = `${CASTTREE_BASE_URL}/casttree/peertube/token`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const requestBody = { phoneNumber, grantType };
      if (grantType === EPeertubeGrantType.refresh_token) {
        requestBody["refreshToken"] = refresh_token;
      }
      let response = await invokeAPICall(baseURL, "POST", requestBody, headers);
      return response.data;
    } catch (err) {
      console.log("error", err);
      throw err;
    }
  },
  uploadVideo: async (channelId, name, videoFile, token) => {
    const baseURL = `${PEERTUBE_BASE_URL}/api/v1/videos/upload`;
    const formData = new FormData();
    formData.append("channelId", channelId);
    formData.append("name", name);
    formData.append("videofile", videoFile);
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    try {
      let response = await invokeAPICall(baseURL, "POST", formData, headers);
      return response.data;
    } catch (err) {
      console.log("error", err);
    }
  },
  uploadFileInChunk: async (
    channelId,
    file,
    setFileUploaderDetail,
    ctToken
  ) => {
    // const maxFileSize = 100 * 1024 * 1024;
    // if (file.size > maxFileSize) {
    //   alert("File size exceeds the 100MB limit. Please upload a smaller file.");
    //   setFileUploaderDetail((fileUploadDetail) => ({
    //     ...fileUploadDetail,
    //     status: "Failed",
    //     reason: "File size exceeds the 100MB limit.",
    //   }));
    //   return;
    // }
    async function initChunkUpload(channelId, file, token) {
      const baseURL = `${PEERTUBE_BASE_URL}/api/v1/videos/upload-resumable`;
      const formData = new FormData();
      formData.set("channelId", channelId);
      formData.set("name", file.name);
      formData.set("filename", file.name);
      formData.set("mimeType", mime.getType(file.name));
      formData.set("size", 310272);
      formData.set("lastModified", 1711872114438);
      formData.set("waitTranscoding", true);
      formData.set("nsfw", "false");
      formData.set("privacy", "2");

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "X-Upload-Content-Length": file.size,
        "X-Upload-Content-Type": mime.getType(file.name),
      };

      try {
        let response = await invokeAPICall(baseURL, "POST", formData, headers);
        return response;
      } catch (err) {
        console.log("error", err);
        throw err;
      }
    }

    async function doChunkUpload(
      file,
      chunkStartIndex,
      chunkEndIndex,
      chunkUploadingLocation,
      token
    ) {
      try {
        const headers = {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${token}`,
          "Content-Range": `bytes ${chunkStartIndex}-${chunkEndIndex}/${file.size}`,
          "X-Upload-Content-Type": mime.getType(file.name),
        };
        const chunk = file.slice(chunkStartIndex, chunkEndIndex + 1);
        const uploadCB = (chunkStartIndex, fileSize) => {
          return (progressEvent) => {
            const totalChunkProcessed = chunkStartIndex + progressEvent.loaded;
            setFileUploaderDetail((fileUploadDetail) => ({
              ...fileUploadDetail,
              progress: Math.floor((totalChunkProcessed / fileSize) * 100),
            }));
          };
        };
        let response = await invokeAPICall(
          chunkUploadingLocation,
          "PUT",
          chunk,
          headers,
          uploadCB(chunkStartIndex, file.size)
        );
        return response;
      } catch (err) {
        if (err.response.status === 308) return err.response;
        else {
          console.log("error", err);
          throw err;
        }
      }
    }

    async function saveMedia(token, body) {
      const baseURL = `${CASTTREE_BASE_URL}/casttree/media/save-media`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        let response = await invokeAPICall(baseURL, "POST", body, headers);
        return response.data;
      } catch (err) {
        console.log("error", err);
        throw err;
      }
    }

    try {
      // console.log("file is ",file)
      // return file;

      let lsFileUploadDetail = localStorage.getItem("fileUploadDetail");
      let fileUploadDetail = lsFileUploadDetail
        ? JSON.parse(lsFileUploadDetail)
        : {};
      const fileKey = file.name + "~" + file.lastModified + "~" + file.size;
      let recentUploadExist;
      let currentFileUploadDetail = {};
      if (
        fileUploadDetail[fileKey] &&
        fileUploadDetail[fileKey]?.lastChunkReceivedAt >
          Date.now() - maxLastChunkReceivedInterval
      ) {
        recentUploadExist = true;
        currentFileUploadDetail = fileUploadDetail[fileKey];
      }
      let initChunkUploadRes;
      if (recentUploadExist && currentFileUploadDetail?.initChunkUploadRes) {
        initChunkUploadRes = currentFileUploadDetail["initChunkUploadRes"];
      } else {
        initChunkUploadRes = await initChunkUpload(
          channelId,
          file,
          JSON.parse(localStorage.getItem("peertubeToken"))?.access_token
        );
        currentFileUploadDetail = {
          ...currentFileUploadDetail,
          initChunkUploadRes,
          lastChunkReceivedAt: Date.now(),
          status: "Initiated",
        };
        fileUploadDetail[fileKey] = currentFileUploadDetail;
        localStorage.setItem(
          "fileUploadDetail",
          JSON.stringify(fileUploadDetail)
        );
      }

      const chunkUploadLocation = initChunkUploadRes.headers.location;
      const fullChunkUploadLocation = `https:${chunkUploadLocation}`;
      let chunkStartIndex = currentFileUploadDetail.nextChunkStartIndex || 0;
      let chunkSize = minChunkSize;
      let chunkUploadRes = {};
      while (
        chunkStartIndex < file.size &&
        chunkUploadRes?.data?.status !== 200 &&
        currentFileUploadDetail.status !== "Success"
      ) {
        const chunkEndIndex =
          chunkStartIndex +
          Math.min(chunkSize, file.size - chunkStartIndex) -
          1;
        chunkUploadRes = await doChunkUpload(
          file,
          chunkStartIndex,
          chunkEndIndex,
          fullChunkUploadLocation,
          JSON.parse(localStorage.getItem("peertubeToken"))?.access_token
        );
        chunkStartIndex += chunkSize;
        if (chunkUploadRes?.status === 200) {
          currentFileUploadDetail["status"] = "Success";
          currentFileUploadDetail["uploadedData"] = chunkUploadRes.data;
        }
        currentFileUploadDetail["nextChunkStartIndex"] = chunkStartIndex;
        currentFileUploadDetail["lastChunkReceivedAt"] = Date.now();
        fileUploadDetail[fileKey] = currentFileUploadDetail;
        localStorage.setItem(
          "fileUploadDetail",
          JSON.stringify(fileUploadDetail)
        );
      }
      setFileUploaderDetail((fileUploadDetail) => ({
        ...fileUploadDetail,
        progress: 100,
        status: "Success",
        uploadedDetails:
          chunkUploadRes?.data || currentFileUploadDetail["uploadedData"],
        reason: JSON.stringify(
          chunkUploadRes?.data || currentFileUploadDetail["uploadedData"]
        ),
      }));

      let mediaId;
      if (currentFileUploadDetail["mediaDetail"]) {
        mediaId = currentFileUploadDetail["mediaDetail"].mediaId;
      } else {
        try {
          const peertubeUploadedData = currentFileUploadDetail["uploadedData"];
          if (!peertubeUploadedData) return;
          const peertubeEmbeddedURL = `${PEERTUBE_BASE_URL}/videos/embed/${peertubeUploadedData?.video?.uuid}`;
          const mediaDetail = await saveMedia(ctToken, {
            privacy: "protected",
            media_type: "video",
            media_format: file.name.split(".")[-1],
            media_size: file.size,
            media_url: currentFileUploadDetail["uploadedData"]?.video?.uuid,
            location: peertubeEmbeddedURL,
            file_name: file.name,
            description: [],
            is_public: "false",
          });
          mediaId = mediaDetail.mediaId;
          currentFileUploadDetail["mediaDetail"] = mediaDetail;
          fileUploadDetail[fileKey] = currentFileUploadDetail;
          localStorage.setItem(
            "fileUploadDetail",
            JSON.stringify(fileUploadDetail)
          );
        } catch (mediaUploadErr) {
          throw mediaUploadErr;
        }
      }

      if (mediaId) {
        setFileUploaderDetail((fileUploadDetail) => ({
          ...fileUploadDetail,
          mediaId,
        }));
      }
    } catch (err) {
      alert("Failed to store the video, Try to re-upload!");
      let fileUploadDetail = {
        status: "Failed",
        reason: err?.message,
      };
      setFileUploaderDetail((sfileUploadDetail) => ({
        ...sfileUploadDetail,
        ...fileUploadDetail,
      }));
    }
  },
  getVideoChannel: async (token, channelName) => {
    const baseURL = `${PEERTUBE_BASE_URL}/api/v1/video-channels/${channelName}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      let response = await invokeAPICall(baseURL, "GET", null, headers);
      return response.data;
    } catch (err) {
      console.log("error", err);
    }
  },
  createPeertubeUser: async (phoneNumber, accessToken) => {
    const baseURL = `${CASTTREE_BASE_URL}/casttree/user/peertube`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      let response = await invokeAPICall(
        baseURL,
        "POST",
        { phoneNumber },
        headers
      );
      return response.data;
    } catch (err) {
      console.log("error", err);
      throw err;
    }
  },
  resumablePeertubeVideo: async (accessToken, upload_id) => {
    const baseURL = `${PEERTUBE_BASE_URL}/api/v1/videos/upload-resumable`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      let response = await invokeAPICall(
        baseURL,
        "DELETE",
        { upload_id },
        headers
      );
      return response.data;
    } catch (err) {
      console.log("error", err);
      throw err;
    }
  },
};

export default VideoUploaderHelper;
