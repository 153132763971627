import "./App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home";
import CreateEvent from "./components/createEvent";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import EventSuccessfully from "./components/eventSuccessfully";
import Login from "./components/login";
import ProtectedRoute from "./components/ProtectedRoute";
import UnProtectedRoute from "./components/UnProtectedRoute";
import ReactGA from 'react-ga4';
import EventLanding from "./components/eventLanding";
import TermsConditions from "./components/termsConditions";
import PrivacyPolicy from "./components/privacyPolicy";
import RefundPolicy from "./components/refundPolicy";
import { APP_CONSTANTS, ENV } from "./const/app.const";
import { HelmetProvider } from "react-helmet-async";
import MetaPixel from "./const/metaPixel";
import DownloadAppScreen from "./components/downloadAppScreen";
const ENV_VARIABLES = APP_CONSTANTS[ENV];
const GOOGLE_ANALYTICS_ID = ENV_VARIABLES.GOOGLE_ANALYTICS_ID;
const MICROSOFT_CLARITY_ID = ENV_VARIABLES.MICROSOFT_CLARITY_ID;
ReactGA.initialize(GOOGLE_ANALYTICS_ID, { debug: true });


const useClarity = () => {
  console.log('MICROSOFT_CLARITY_ID', MICROSOFT_CLARITY_ID);
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", MICROSOFT_CLARITY_ID);
  }, [MICROSOFT_CLARITY_ID]);
};

const GATracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    setInitialized(true);
  }, []);
  useEffect(() => {
    if (initialized) {
      ReactGA.send(location.pathname + location.search);
    }
  }, [initialized, location]);
};


const App = () => {
  useClarity();
  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href"))?.scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <HelmetProvider>
      <div
        className="App"
        style={{ backgroundColor: "#000", background: "#000", height: "100vh" }}
      >
        <Router>
          <MetaPixel />
          <GATracker />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/events" element={<EventLanding />} />
            <Route path="/event" element={<EventLanding />} />
            <Route path="/login" element={<EventLanding />} />
            <Route path="/event-create" element={<EventLanding />} />
            <Route path="/eventAppScreen" element={<DownloadAppScreen />} />
            {/* <Route
              path="/login"
              element={<UnProtectedRoute component={<Login />} />}
            />
            <Route
              path="/event-create"
              element={<ProtectedRoute component={<EventLanding />} />}
            /> */}
            <Route path="/eventssuccessfully" element={<EventLanding />} />
            <Route path="/termsConditions" element={<TermsConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/refundPolicy" element={<RefundPolicy />} />
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
