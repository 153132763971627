import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import logoImage from "../assets/casttree_logo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import HelpIcon from "@mui/icons-material/Help"; // Import Link and animateScroll from react-scroll
import { useNavigate } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import authService from "../services/authService";

const EventNavbarComponent = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header-nav");
      if (window.scrollY > 200) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    authService.doLogout();
  };

  return (
    <>
      <Navbar
        className="header-nav navbar-light event-header container"
        fixed="top"
        collapseOnSelect
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
          position: "relative",
        }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5 ct-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </Navbar.Brand>
        </Fade>

        <Nav className="ct-pointer">
          <Fade direction="right" cascade>
            {/* <Nav.Link
              className="px-lg-3"
              rel="noopener noreferrer"
              target="_blank"
            > */}
            <a
              target="_blank"
              style={{ textDecorationLine: "none", cursor: "pointer" }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfvV9trCTWLbXWcVnHEZl2CBOEzQiB7CXf6c7aNhYDxZgQCWg/viewform"
            >
              <div style={{ display: "flex", cursor: "pointer", paddingTop: 5 }}>
                <HelpIcon style={{ fill: "white", fontSize: 25 }} />
                <p
                  className="ct-about-para-text mb-0"
                  style={{ paddingLeft: 10 }}
                >
                  Help
                </p>
              </div>
            </a>
            {/* </Nav.Link> */}
          </Fade>
          {/* {ctToken && (
            <Nav>
              <Fade direction='right' cascade>
                <Nav.Link className="px-lg-3 ct-pointer" onClick={handleLogout}>
                  <LogoutOutlinedIcon
                    style={{ fill: "white", fontSize: 20, cursor: "pointer" }}
                  />
                </Nav.Link>
              </Fade>
            </Nav>
          )} */}
        </Nav>
      </Navbar>
    </>
  );
};

export default EventNavbarComponent;
