import React, { useState } from "react";
import AwardImg from "../assets/award_img.png";
import { Fade } from "react-awesome-reveal";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import EventNavbarComponent from "./eventNavbar";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import OtpInput from "react-otp-input";
import useResendTimer from "./useResendTimer";
import AuthService from "../services/authService";
import toast, { Toaster } from "react-hot-toast";
import SEO from "./seo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [isValidPhnNumber, setIsValidPhnNumber] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const [canResendOTP, setCanResendOTP] = useState(false);
  const [showOTPDialog, setShowOTPDialog] = React.useState(false);
  const [disableVerifyOTPBtn, setDisableVerifyOTPBtn] = useState(false);
  const { timer, resetTimer } = useResendTimer(30);
  const [otp, setOtp] = useState("");
  const [userData, setUser] = useState("");
  const authServices = AuthService;

  const handleInputChange = (event) => {
    let value = event.target.value.toString();
    value = value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setPhoneNumber(value);
    setIsValidPhnNumber(value.length === 10 && /^[6-9]\d{9}$/.test(value));
  };

  const handleGenerateOTP = async () => {
    try {
      setIsGeneratingOTP(true);
      const response = await authServices.generateOTP(phoneNumber.toString());
      setIsGeneratingOTP(false);
      setShowOTPDialog(true);
      startCountdown();
      toast.success("OTP generated successfully");
    } catch (error) {
      toast.error("Enter valid phone number");
      setIsGeneratingOTP(false);
    }
  };

  const loginOTPVerify = async () => {
    try {
      const otpResponse = await authServices.otpLogin(
        phoneNumber,
        otp.toString()
      );
      setDisableVerifyOTPBtn(true);
      const userData = otpResponse.user;
      const otpResponseData = otpResponse?.userOrganization[0].organizationId;
      localStorage.setItem("access_token", otpResponse.access_token);
      localStorage.setItem("user_detail", JSON.stringify(userData));
      localStorage.setItem("user_otpResponse_data", JSON.stringify(otpResponseData));
      localStorage.setItem("user_id", userData._id);
      setUser(userData);
      let tabToNavigate = null; // Initialize to null
      if (!checkUserDataExist(userData)) {
        tabToNavigate = 1; // Navigate to tab 1 if userName is null
      } else {
        try {
          const response = await authServices.getProjectList();
          if (response?.data?.length > 0) {
            tabToNavigate = 3; // Navigate to tab 3 if projectList length is 1
          } else {
            tabToNavigate = 2; // Navigate to tab 2 if projectList length is 0
          }
        } catch (err) {
          tabToNavigate = 2;
        }
      }
      if (tabToNavigate !== null) {
        navigate(`/event-create?tab=${tabToNavigate}`);
        toast.success("Login successfully");
      } else {
        // Handle case where tabToNavigate is still null
        console.error("Unable to determine tab to navigate");
      }
    } catch (error) {
      toast.error("Invalid OTP");
      setDisableVerifyOTPBtn(false);
    }
  };

  const checkUserDataExist = (userData) => {
    return (
      userData.userName &&
      userData.emailId &&
      userData.gender &&
      userData.dateOfBirth &&
      userData.state &&
      userData.city
    );
  };

  const startCountdown = () => {
    setCanResendOTP(false);
    const countDown = () => {
      setResendTime((prevTime) => {
        if (prevTime === 0) {
          setCanResendOTP(true);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
      if (resendTime > 0) {
        setTimeout(countDown, 1000);
      }
    };
    countDown();
  };

  const handleClose = () => {
    setShowOTPDialog(false);
    setPhoneNumber("");
    startCountdown();
    resetTimer();
    setResendTime(0);
    window.location.reload();
  };

  const handleResendOTP = () => {
    if (timer === 0) {
      startCountdown();
      resetTimer();
      setShowOTPDialog(true);
      handleGenerateOTP();
      setResendTime(30);
      setOtp("");
    }
  };

  const handleOTPInputChange = (value) => {
    setOtp(value);
    if (value.length === 4) {
      setDisableVerifyOTPBtn(true);
    } else {
      setDisableVerifyOTPBtn(false);
    }
  };

  return (
    <div className="" style={{ background: "#000", color: "white" }}>
      <SEO title="Casttree Login" description="" />
      <div className="landing-page-main">
        <EventNavbarComponent />
      </div>
      <div className="container mt-0">
        <div
          style={{
            position: "relative",
            bottom: "-5px",
            left: 0,
            width: "100%",
            height: "3px",
            background: `linear-gradient(to right, #EE35FF 0%, #FF8761 20%, #3D3D3D 20%, #3D3D3D 100%)`, // Gradient for the border
            zIndex: 1,
          }}
        ></div>
        <div className="pt-5 lg-px-5 lg-mx-5">
          <div className="head">
            <div className="head-div">
              <Fade direction="down" cascade>
                {/* Tell us about you Section */}
                <div className="">
                  <div className="ct-d-flex-justify-center-web ct-flex-row mb-5 pb-4">
                    <div className="event-first-section">
                      <div className="">
                        <div className="ct-text-left">
                          <h1 className="ct-cirka-size-30">
                            Tell us about you
                          </h1>
                          <div className="mt-2 mb-3">
                            <p className="panel-sub-title">
                              We need to know a little bit about you to proceed
                              with the application
                            </p>
                          </div>

                          <div className="">
                            <div className="mt-5">
                              <p
                                className="copy-right-text mb-2"
                                style={{ fontWeight: 600, color: "#FFF" }}
                              >
                                Mobile Number
                              </p>

                              <input
                                type="number"
                                placeholder="enter your mobile number"
                                value={phoneNumber}
                                onChange={handleInputChange}
                                className="phone-no-input"
                              />

                              <button
                                onClick={handleGenerateOTP}
                                className="verify-btn"
                                disabled={!isValidPhnNumber || isGeneratingOTP}
                                style={{
                                  background: isValidPhnNumber
                                    ? "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)"
                                    : "gray",
                                }}
                              >
                                {isGeneratingOTP ? "Generating..." : "Get OTP"}
                              </button>

                              <BootstrapDialog
                                aria-labelledby="customized-dialog-title"
                                open={showOTPDialog}
                              >
                                <p
                                  className="ct-text-18 ct-text-weight-600 ct-text-light p-3 pt-3"
                                  id="customized-dialog-title"
                                >
                                  Enter OTP
                                </p>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: "#FFF",
                                  }}
                                  className="ct-pointer"
                                >
                                  <HighlightOffIcon className="ct-text-18" />
                                </IconButton>
                                <DialogContent dividers>
                                  <div className="dialog-custom-input">
                                    <OtpInput
                                      value={otp}
                                      shouldAutoFocus
                                      onChange={handleOTPInputChange}
                                      numInputs={4}
                                      isInputNum={true}
                                      type="number"
                                      inputStyle={{
                                        width: "42px",
                                        height: "46px",
                                        marginRight: "20px",
                                        borderWidth: 1,
                                        backgroundColor: "#D9D9D9",
                                        borderRadius: 5,
                                        fontSize: 20,
                                      }}
                                      className="customOtpContainer"
                                      renderSeparator={<span></span>}
                                      renderInput={(props) => (
                                        <input
                                          {...props}
                                          inputMode="numeric"
                                          type="number"
                                          pattern="[0-9]*"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="mt-4 ct-text-center">
                                    {timer > 0 ? (
                                      <p
                                        className="ct-text-14 mb-2"
                                        style={{
                                          fontWeight: 600,
                                          color: "#FFF",
                                        }}
                                      >
                                        You can resend code in{" "}
                                        <span style={{ color: "#EE35FF" }}>
                                          {" "}
                                          {resendTime < 10
                                            ? `0${resendTime}`
                                            : resendTime}{" "}
                                          seconds{" "}
                                        </span>
                                      </p>
                                    ) : (
                                      canResendOTP && (
                                        <p
                                          className="ct-text-14 mb-2"
                                          style={{
                                            fontWeight: 600,
                                            color: "#FFF",
                                          }}
                                        >
                                          Didn't receive the OTP?{" "}
                                          <span
                                            onClick={handleResendOTP}
                                            className="ct-pointer"
                                            style={{ color: "#EE35FF" }}
                                          >
                                            Resend OTP
                                          </span>
                                        </p>
                                      )
                                    )}
                                  </div>
                                  <div className="mt-4 ct-text-center dialog-custom-input">
                                    <p
                                      className="ct-text-14 mb-2"
                                      style={{ fontWeight: 600, color: "#FFF" }}
                                    >
                                      By clicking on verify, you accept to the{" "}
                                      <span
                                        className="ct-pointer"
                                        style={{
                                          textDecoration: "underline",
                                          color: "#EE35FF",
                                        }}
                                      >
                                        <a
                                          href="/termsConditions"
                                          target="_blank"
                                          style={{ color: "#ee35ff" }}
                                        >
                                          terms and conditions
                                        </a>
                                      </span>{" "}
                                      of Casttree events and awards
                                    </p>
                                  </div>
                                  <div className="mt-4 ct-text-center">
                                    <button
                                      style={{
                                        background: otp.length
                                          ? "linear-gradient(to right, rgb(238, 53, 255) 0%, rgb(255, 135, 97) 100%)"
                                          : "linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)",
                                        color: otp.length ? "#FFF" : "#000",
                                      }}
                                      onClick={loginOTPVerify}
                                      disabled={!disableVerifyOTPBtn}
                                      className="next-btn-small mbl-my-4"
                                    >
                                      Verify
                                    </button>
                                  </div>
                                </DialogContent>
                              </BootstrapDialog>
                            </div>

                            {/* <div className="mt-3 pt-1 ct-check-box-row ct-display-flex">
                              <FormGroup
                                className="ct-display-flex"
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      defaultChecked
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 28,
                                          color: "#ee35ff",
                                        },
                                      }}
                                    />
                                  }
                                />
                                <label style={{ paddingLeft: 10 }}>
                                  By proceeding, you are accepting our{" "}
                                  <span
                                    className="ct-pointer"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#EE35FF",
                                    }}
                                  >
                                    terms and conditions
                                  </span>
                                </label>
                              </FormGroup>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <Toaster position="top-center" reverseOrder={false} />
                    </div>

                    <div className="event-second-section">
                      <img src={AwardImg} className="ct-full-width" />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
