import React from "react";
import { Fade } from "react-awesome-reveal";
import CalendarToday from "@mui/icons-material/CalendarToday";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import landingVideo from "../assets/landing_video.mp4";
import mouseIcon from "../assets/mouse_icon.png";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService";

const Banner = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");
  const handleScrollDownClick = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  // const handleApplyNowClick = async () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   if (ctToken) {
  //     try {
  //       const response = await authService.getProjectList();
  //       if (response?.data?.length > 0) {
  //         navigate(`/event-create?tab=${3}`);
  //       } else {
  //         navigate(`/event-create?tab=${2}`);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching project list:", error);
  //     }
  //   } else {
  //     navigate("/login");
  //   }
  // };

  const handleApplyNowClick = async () => {
    navigate("/eventAppScreen");
  };
  return (
    <div
      style={{
        background: "#000",
        color: "white",
        paddingTop: 0,
        paddingLeft: 0,
      }}
      className="main-div container landing-page-main"
      id="banner"
    >
      <video autoPlay playsInline muted loop className="video-height">
        <source src={landingVideo} type="video/mp4" />
      </video>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to top, rgb(0 0 0 / 100%) 10%, rgba(0, 0, 0, 0) 180%)",
        }}
      ></div>

      <div>
        <Fade direction="down" casecade>
          <div className="head landing-text-top">
            <div className="ct-d-flex-justify-center ct-px-30 pt-5 mbl-ml-10">
              <div style={{ display: "flex", marginBottom: 15 }}>
                <CalendarToday style={{ fill: "white", fontSize: 20 }} />
                <p className="head-div-fourth-text" style={{ paddingLeft: 10 }}>
                  <b>September 12th - 13th</b>
                </p>
              </div>
              <div style={{ display: "flex", marginBottom: 15 }}>
                <LocationOnOutlined style={{ fill: "white", fontSize: 20 }} />
                <p className="head-div-fourth-text" style={{ paddingLeft: 10 }}>
                  <b>Vels University, Chennai</b>
                </p>
              </div>
            </div>
            <div className="head-div mx-auto ct-top-50 ct-center-fixed mt-5 mb-5">
              <section className="div-1 home-text-center">
                <p className="landing-gradient-text mb-4">
                  India’s Largest Showcase for Short Films, Music & Dance
                </p>
                <h1 className="ct-banner-title">CASTTREE AWARDS ‘24</h1>
                <div className="pt-4 animation-text">
                  <h3 className="ct-sub-banner-title">
                    WIN UPTO 2 LAKHS OF CASH PRIZE
                  </h3>
                </div>

                <div className="mt-5 pt-5 home-text-center banner-apply-now-btn">
                  <a
                    className="my-3 apply-now-fill ct-pointer"
                    onClick={handleApplyNowClick}
                    style={{ cursor: "pointer", color: "#FFF" }}
                  >
                    VIEW SUBMISSION
                  </a>
                </div>
                <div className="mt-3 pt-2 ct-text-center">
                  <p className="" style={{fontWeight: 500}}>
                  Application Closed
                  </p>
                </div>
                <div
                  className="mt-4 pt-4"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={mouseIcon} width={20} />
                  <p
                    className=""
                    style={{ fontSize: 11, paddingLeft: 5, paddingTop: 2 }}
                  >
                    SCROLL DOWN{" "}
                  </p>
                </div>
              </section>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <span className="scroll-btn">
            <a href="#home">
              <span className="mouse" style={{ color: "#FFFs" }}>
                <span></span>
              </span>
            </a>
          </span>
        </Fade>
      </div>
    </div>
  );
};

export default Banner;
